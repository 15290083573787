import React from 'react';
import './AboutUs.css'; // Import your CSS
import HeaderBar from './HeaderBar'; // Import the HeaderBar for consistent navigation

function AboutUs() {
  return (
    <div className="main-container"> {/* Main container for full background */}
      <HeaderBar />
      <div className="bubble-container">
        <h1>About AigencyOne</h1>
        {/* Mission Section */}
        <section className="mission-section">
          <h2>Our Mission</h2>
          <p>
            At AigencyOne, our mission is simple: to help businesses streamline their operations and achieve success through innovative, reliable technology solutions. We believe in the power of custom applications that address each business's unique challenges, and we're committed to being your trusted partner in the digital transformation journey.
          </p>
        </section>
      </div>

      <div className="bubble-container">
        {/* Founder Section */}
        <section className="founder-section">
          <h2>About the Founder</h2>
          <p>
            AigencyOne was founded by a combat veteran who has carried over the discipline, leadership, and resilience gained in military service into the business world. After serving in the armed forces, our founder discovered a passion for technology and the immense potential it holds for business growth and efficiency.
          </p>
          <p>
            With a deep understanding of the challenges businesses face in organizing their tech life, from infrastructure to application development, our founder set out to create a company dedicated to solving these issues. AigencyOne exists to bridge the gap between businesses and the digital tools they need to thrive, with solutions that are as robust and resilient as the founder who built it.
          </p>
        </section>
      </div>

      <div className="bubble-container">
        {/* Why We Do It Section */}
        <section className="why-we-do-it">
          <h2>Why We Do It</h2>
          <p>
            We understand that running a business is demanding, and dealing with complex tech solutions shouldn't add to that burden. That's why we're here. At AigencyOne, we don’t just create apps or websites; we create solutions that empower businesses to stay focused on what they do best. Whether it’s building custom web or mobile applications or providing technical support, we are passionate about helping businesses simplify and streamline their operations.
          </p>
          <p>
            For us, it's not just about delivering a product—it's about delivering value. We're here to help businesses optimize, grow, and succeed in the ever-evolving digital landscape.
          </p>
        </section>
      </div>

      <div className="bubble-container">
        {/* Core Values Section */}
        <section className="core-values">
          <h2>Our Core Values</h2>
          <ul className="aboutus-list"> {/* Scoped list class */}
            <li><strong>Integrity:</strong> We believe in being transparent, honest, and accountable in everything we do.</li>
            <li><strong>Excellence:</strong> We strive for excellence in every project, big or small.</li>
            <li><strong>Innovation:</strong> We leverage the latest technology to provide cutting-edge solutions for our clients.</li>
            <li><strong>Customer-Centric:</strong> We put our clients first and build solutions that meet their unique needs.</li>
            <li><strong>Community:</strong> As a veteran-founded business, we’re proud to support and give back to our community, both locally and globally.</li>
          </ul>
        </section>
      </div>

      <div className="bubble-container">
        {/* Call to Action */}
        <section className="cta-section">
          <h2>Ready to Work Together?</h2>
          <p>
            If you're looking for reliable, custom technology solutions to help your business thrive, we’d love to hear from you. Contact us today for a consultation and find out how AigencyOne can support your business goals.
          </p>
          <a href="/contact-us" className="btn primary-btn">Get in Touch</a> {/* Assuming you have a contact page */}
        </section>
      </div>
    </div>
  );
}

export default AboutUs;
