import React, { useState } from 'react';
import './Products.css';
import HeaderBar from './HeaderBar';
import { db } from '../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

function Products() {
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [contactName, setContactName] = useState('');
  const [phone, setPhone] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [industry, setIndustry] = useState('');
  const [interestLevel, setInterestLevel] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !companyName || !contactName) {
      setError('Please fill in the required fields.');
      return;
    }

    try {
      const docRef = await addDoc(collection(db, 'OSLMPMWaitList'), {
        email,
        companyName,
        contactName,
        phone,
        companySize,
        industry,
        interestLevel,
        timestamp: new Date(),
      });
      setIsSubmitted(true);
      setError('');
      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
      setError('Failed to submit. Please try again.');
    }
  };

  return (
    <div className="products-page">
      <HeaderBar />
      <section className="hero-section">
        <h1>Introducing the Future of Company Management</h1>
        <p>
          Our integrated Onboarding, Learning, and Project Management system, powered by AI, is designed to be one of the most robust tools available for managing all aspects of your company in one seamless platform. 
          Customize the application with your company's logo, colors, and branding to make it feel uniquely yours, and provide a cohesive experience for your team.
        </p>
        <p className="coming-soon">Coming Soon...</p>
      </section>

      <section className="features-section">
        <h2>What to Expect</h2>
        <div className="features">
          <div className="feature-card">
            <h3>Onboarding</h3>
            <p>Streamline the onboarding process with automated workflows, ensuring a smooth experience for new hires from day one.</p>
          </div>
          <div className="feature-card">
            <h3>Learning</h3>
            <p>Empower your team with an AI-enhanced learning platform that adapts to their needs, offering personalized training and skill development.</p>
          </div>
          <div className="feature-card">
            <h3>Project Management</h3>
            <p>Optimize project workflows with a powerful management tool, tracking resources, timelines, and progress in real-time.</p>
          </div>
          <div className="feature-card">
            <h3>AI Integration</h3>
            <p>Leverage AI for analytics, insights, and automated decision-making, transforming how your company operates at every level.</p>
          </div>
        </div>
      </section>

      <section className="cta-section">
        <h2>Stay Tuned for Updates</h2>
        <p>Be the first to know when we launch! Sign up for early access and exclusive updates.</p>
        {isSubmitted ? (
          <p>Thank you for signing up! We’ll keep you updated.</p>
        ) : (
          <form onSubmit={handleSubmit} className="waitlist-form">
            <input
              type="text"
              placeholder="Company Name (required)"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Contact Person's Name (required)"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Email (required)"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              type="text"
              placeholder="Company Size"
              value={companySize}
              onChange={(e) => setCompanySize(e.target.value)}
            />
            <input
              type="text"
              placeholder="Industry Type"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            />
            <select
              value={interestLevel}
              onChange={(e) => setInterestLevel(e.target.value)}
              className="interest-level"
            >
              <option value="">Interest Level</option>
              <option value="Just Browsing">Just Browsing</option>
              <option value="Interested">Interested</option>
              <option value="Very Interested">Very Interested</option>
            </select>
            <button type="submit" className="notify-btn">Notify Me</button>
            {error && <p className="error">{error}</p>}
          </form>
        )}
      </section>
    </div>
  );
}

export default Products;
