import React, { useState } from 'react';
import './BusinessSystems.css';
import { useNavigate } from 'react-router-dom';
import HeaderBar from './HeaderBar';

const servicesData = [
  {
    title: "Enterprise Resource Planning (ERP)",
    summary: [
      "Centralized management of business functions",
      "Real-time data integration for better decisions",
      "Enhances efficiency and reduces costs"
    ],
    details: `
      **ERP systems unify core business processes** across departments such as finance, HR, procurement, and supply chain, allowing for seamless operation and better data access:
      
      - **Centralized Operations**: Streamline workflows by bringing all business functions into a single system, reducing redundancy and improving communication.
      - **Real-Time Insights**: Access up-to-the-minute data to make informed decisions on procurement, financials, and production.
      - **Scalability**: Adapt the ERP to grow with your business, adding modules for additional departments as needed.
      
      **Industry Applications**:
      - **Manufacturing**: Optimize inventory, manage production schedules, and ensure timely order fulfillment.
      - **Retail**: Track sales, manage inventory in real time, and gain insights into customer buying patterns.
      - **Healthcare**: Integrate patient records with billing, procurement, and HR to ensure smooth operation.
      - **Finance**: Manage accounting, compliance, and reporting to keep financial operations streamlined.
    `,
    industries: ["Manufacturing", "Retail", "Healthcare", "Finance", "Logistics"]
  },
  {
    title: "Customer Relationship Management (CRM)",
    summary: [
      "Centralizes customer data and interactions",
      "Enhances customer satisfaction and loyalty",
      "Supports targeted marketing efforts"
    ],
    details: `
      **CRM systems offer a complete view of customer relationships** by tracking all interactions, purchases, and engagement across channels:
      
      - **Customer Insights**: Capture customer preferences, purchase history, and interaction data to personalize marketing and improve service.
      - **Automated Sales Pipelines**: Streamline sales activities with tools for lead tracking, follow-ups, and conversions.
      - **Enhanced Marketing Campaigns**: Tailor your campaigns based on customer segmentation, improving engagement and conversion rates.
      
      **Industry Applications**:
      - **Retail**: Build customer loyalty with personalized promotions and track sales interactions for improved service.
      - **Real Estate**: Nurture leads, track prospects, and stay connected throughout the buyer journey.
      - **Healthcare**: Manage patient communications and engagement, ensuring personalized care and follow-ups.
      - **Finance**: Build trust with clients by tracking interactions and providing timely, relevant financial insights.
    `,
    industries: ["Retail", "Finance", "Healthcare", "Real Estate", "Hospitality"]
  },
  {
    title: "Human Capital Management (HCM)",
    summary: [
      "Automates HR functions from hiring to payroll",
      "Enhances employee engagement and retention",
      "Ensures compliance with labor regulations"
    ],
    details: `
      **HCM systems handle all aspects of HR operations**, from recruitment to retirement, improving employee management and engagement:
      
      - **Streamlined Hiring**: Automate job postings, applicant tracking, and onboarding for a seamless recruitment process.
      - **Employee Engagement**: Foster a culture of growth with tools for performance management, feedback, and goal setting.
      - **Payroll & Benefits**: Manage payroll, tax deductions, benefits, and compliance with accuracy and efficiency.
      
      **Industry Applications**:
      - **Healthcare**: Ensure regulatory compliance, manage shift schedules, and streamline payroll for large teams.
      - **Education**: Manage faculty recruitment, benefits, and professional development.
      - **Finance**: Track regulatory compliance, manage performance, and oversee payroll efficiently.
    `,
    industries: ["Healthcare", "Education", "Finance", "Technology", "Manufacturing"]
  },
  {
    title: "Supply Chain Management (SCM)",
    summary: [
      "Optimizes logistics, procurement, and inventory",
      "Enhances supplier relationships and efficiency",
      "Reduces operational costs and lead times"
    ],
    details: `
      **SCM systems provide end-to-end visibility into the supply chain**, helping businesses manage everything from procurement to logistics:
      
      - **Supplier Management**: Track supplier performance, manage contracts, and enhance procurement efficiency.
      - **Inventory Optimization**: Avoid stockouts and overstock situations by forecasting demand and managing inventory accurately.
      - **Real-Time Tracking**: Monitor logistics in real-time, enabling quick adjustments to ensure timely delivery.
      
      **Industry Applications**:
      - **Manufacturing**: Improve procurement and inventory control, reducing waste and downtime.
      - **Logistics**: Enhance route planning and shipment tracking, ensuring timely deliveries.
      - **Retail**: Efficiently manage supplier relationships and restock processes to meet customer demand.
    `,
    industries: ["Manufacturing", "Retail", "Logistics", "Healthcare", "Automotive"]
  },
  {
    title: "Project and Portfolio Management (PPM)",
    summary: [
      "Aligns projects with organizational goals",
      "Tracks resources, timelines, and budgets",
      "Enhances project efficiency and accountability"
    ],
    details: `
      **PPM systems enable comprehensive management of projects**, ensuring alignment with business objectives and resource availability:
      
      - **Resource Management**: Allocate and manage resources based on project needs, reducing bottlenecks.
      - **Timeline Tracking**: Set milestones, track project progress, and manage budgets effectively.
      - **Risk Assessment**: Identify and mitigate risks to keep projects on track and within budget.
      
      **Industry Applications**:
      - **Construction**: Manage timelines, budgets, and resources effectively across multiple projects.
      - **Consulting**: Track client projects, allocate resources, and ensure project profitability.
      - **Marketing**: Plan and execute marketing campaigns with a clear view of resources and timelines.
    `,
    industries: ["Construction", "Technology", "Finance", "Consulting", "Marketing"]
  },
  {
    title: "E-commerce Platforms",
    summary: [
      "Facilitates online sales and customer engagement",
      "Integrates seamlessly with business systems",
      "Offers a user-friendly shopping experience"
    ],
    details: `
      **Our e-commerce platform integrates with your business systems**, providing an optimal customer shopping experience:
      
      - **Product Catalog Management**: Easily manage product listings, descriptions, pricing, and promotions.
      - **Secure Payment Gateway**: Supports multiple payment methods, ensuring a smooth and secure checkout.
      - **Customer Analytics**: Track customer behavior, preferences, and purchasing patterns for targeted marketing.
      
      **Industry Applications**:
      - **Retail**: Expand market reach, track inventory in real-time, and personalize customer engagement.
      - **Fashion**: Offer personalized shopping experiences with a seamless product catalog and order tracking.
      - **Food & Beverage**: Manage inventory and streamline the online ordering process.
    `,
    industries: ["Retail", "Fashion", "Electronics", "Food & Beverage", "Automotive"]
  },
  {
    title: "Business Intelligence (BI) and Analytics",
    summary: [
      "Provides actionable insights from data",
      "Enhances decision-making and strategy",
      "Supports growth through data-driven insights"
    ],
    details: `
      **BI tools process and analyze data across business functions**, providing visual dashboards and insights to drive decision-making:
      
      - **Data Visualization**: Use charts and dashboards to monitor KPIs and trends in real-time.
      - **Predictive Analytics**: Forecast future trends based on historical data to aid planning.
      - **Customizable Reports**: Generate reports tailored to your business needs, supporting data-driven decisions.
      
      **Industry Applications**:
      - **Finance**: Monitor key financial metrics and generate regulatory reports.
      - **Retail**: Analyze sales data to identify trends and optimize inventory.
      - **Healthcare**: Track patient care metrics and operational efficiency.
    `,
    industries: ["Finance", "Healthcare", "Retail", "Technology", "Manufacturing"]
  },
  {
    title: "Collaboration and Communication Platforms",
    summary: [
      "Facilitates seamless team collaboration",
      "Integrates chat, video, and document sharing",
      "Supports remote and in-office environments"
    ],
    details: `
      Collaboration and Communication Platforms are designed to break down communication barriers within teams, whether they're working remotely or in an office. These tools centralize conversations, document sharing, and project updates, ensuring everyone stays on the same page:

      - **Real-Time Communication**: Includes chat, video conferencing, and voice calls to enable immediate collaboration, reducing delays.
      - **Document Sharing and Co-Editing**: Centralized storage for documents allows team members to collaborate in real-time, ensuring everyone has access to the latest files.
      - **Task and Project Tracking**: Integrates task management features, allowing teams to assign tasks, set deadlines, and monitor project progress.
      - **Security and Compliance**: Protects communication with robust security features, ensuring data remains secure, particularly for regulated industries.

      **Industry Applications**:
      - **Education**: Enables online classes, collaborative assignments, and resource sharing between students and teachers.
      - **Technology**: Facilitates agile project management with real-time updates for dispersed teams.
      - **Consulting**: Enhances client collaboration, allowing consultants to share insights and reports securely.
      - **Healthcare**: Improves communication between medical professionals, supporting better patient care.
      - **Finance**: Ensures secure and compliant communication for teams handling sensitive financial information.
    `,
    industries: ["Education", "Technology", "Consulting", "Healthcare", "Finance"]
  },
  {
    title: "Workflow Automation and Business Process Management (BPM)",
    summary: [
      "Automates repetitive tasks and workflows",
      "Monitors performance and identifies bottlenecks",
      "Improves productivity and operational efficiency"
    ],
    details: `
      Workflow Automation and BPM solutions are designed to streamline business operations by automating repetitive tasks, allowing employees to focus on more strategic work. These tools support process modeling, automation, and performance monitoring:

      - **Process Modeling**: Visual tools for designing workflows allow businesses to map out processes, identifying inefficiencies and opportunities for automation.
      - **Task Automation**: Automates routine tasks such as data entry, approval workflows, and notifications, reducing errors and saving time.
      - **Performance Monitoring**: Tracks workflow efficiency, providing insights into bottlenecks and areas for improvement.
      - **Scalability**: Scales as the organization grows, ensuring that workflows remain efficient, even as the number of processes increases.

      **Industry Applications**:
      - **Finance**: Automates approval processes for expenses, invoicing, and financial reporting.
      - **Healthcare**: Streamlines patient onboarding and billing processes.
      - **Manufacturing**: Enhances production line efficiency by automating inventory checks and quality control processes.
      - **Retail**: Optimizes supply chain operations by automating order processing and inventory management.
      - **Logistics**: Automates dispatch and routing, improving delivery times and reducing operational costs.
    `,
    industries: ["Finance", "Healthcare", "Manufacturing", "Retail", "Logistics"]
  },
  {
    title: "Custom Application Development",
    summary: [
      "Tailored software solutions for unique needs",
      "Integrates seamlessly with existing systems",
      "Enhances operational flexibility and scalability"
    ],
    details: `
      Custom Application Development involves creating software solutions tailored to the unique requirements of a business, addressing specific challenges that off-the-shelf software cannot solve:

      - **Requirement Analysis**: Engages with stakeholders to understand the unique needs of the business, ensuring the final product meets exact specifications.
      - **Seamless Integration**: Ensures the custom software integrates with existing systems, such as CRMs, ERPs, and other tools, for a smooth transition.
      - **Enhanced Scalability**: Built to adapt as the business grows, with flexible architecture that allows for the addition of new features as needed.
      - **User-Centric Design**: Focuses on creating a user-friendly interface that enhances employee productivity and customer experience.

      **Industry Applications**:
      - **Technology**: Provides tailored solutions for complex software needs, from system integration to custom dashboards.
      - **Finance**: Customizes financial tools to handle specific reporting, compliance, and transaction requirements.
      - **Healthcare**: Builds applications for patient management, telemedicine, and compliance tracking.
      - **Education**: Develops learning platforms, student management systems, and assessment tools to support digital education.
      - **Retail**: Creates custom e-commerce platforms that support unique branding and customer engagement strategies.
    `,
    industries: ["Technology", "Finance", "Healthcare", "Education", "Retail"]
  },
  {
    title: "Document Management Systems (DMS)",
    summary: [
      "Stores, manages, and tracks digital documents",
      "Enhances security and regulatory compliance",
      "Provides easy access to information"
    ],
    details: `
      Document Management Systems (DMS) offer a centralized repository for storing, managing, and tracking electronic documents. These systems improve security, compliance, and document accessibility:

      - **Centralized Storage**: Provides a secure location to store documents, enabling easy retrieval and reducing the risk of loss.
      - **Access Control and Security**: Allows administrators to set access permissions, ensuring sensitive information is only accessible to authorized personnel.
      - **Version Control**: Tracks changes and maintains version histories, allowing users to revert to previous document versions if necessary.
      - **Compliance and Audit Trails**: Records access and changes to documents, helping organizations comply with regulatory requirements.

      **Industry Applications**:
      - **Legal**: Stores contracts, case files, and other sensitive documents securely.
      - **Healthcare**: Manages patient records, lab results, and other medical documentation.
      - **Finance**: Organizes financial records, compliance documents, and transaction histories.
      - **Education**: Stores student records, course materials, and administrative documents.
      - **Government**: Ensures secure access to sensitive documents while maintaining compliance with public records laws.
    `,
    industries: ["Legal", "Healthcare", "Finance", "Education", "Government"]
  },
  {
    title: "Customer Service Management Systems",
    summary: [
      "Handles inquiries, complaints, and support requests",
      "Streamlines support processes for faster resolution",
      "Enhances customer satisfaction and loyalty"
    ],
    details: `
      Customer Service Management Systems centralize customer interactions, making it easier for support teams to resolve inquiries and complaints efficiently:

      - **Ticketing and Case Management**: Assigns tickets to specific support agents, tracking issues from submission to resolution.
      - **Knowledge Base**: Provides a searchable repository of FAQs, troubleshooting guides, and resources, allowing customers to find answers on their own.
      - **Omnichannel Support**: Integrates with various communication channels, such as phone, email, chat, and social media, for seamless support.
      - **Analytics and Reporting**: Tracks performance metrics, such as resolution times and customer satisfaction, to identify areas for improvement.

      **Industry Applications**:
      - **Telecommunications**: Manages customer inquiries and technical support cases effectively.
      - **Retail**: Handles product inquiries, returns, and complaints, improving customer satisfaction.
      - **Finance**: Provides quick resolutions to account-related inquiries and issues.
      - **Technology**: Offers technical support and troubleshooting, helping customers use products effectively.
      - **Healthcare**: Manages patient inquiries and service requests, ensuring timely responses.
    `,
    industries: ["Telecommunications", "Retail", "Finance", "Technology", "Healthcare"]
  },
  {
    title: "Inventory Management Systems",
    summary: [
      "Tracks inventory levels and optimizes stock",
      "Prevents overstocking and stockouts",
      "Improves supply chain efficiency"
    ],
    details: `
      Inventory Management Systems are essential for businesses that need to maintain precise control over their stock levels. These systems ensure that you have the right amount of inventory to meet demand without overstocking, which ties up capital, or understocking, which can lead to lost sales:

      - **Real-Time Tracking**: Continuously monitors stock levels, orders, and deliveries to provide up-to-date information.
      - **Automatic Reordering**: Sets reorder points to trigger automatic orders when stock levels drop below a certain threshold, ensuring a steady supply.
      - **Demand Forecasting**: Analyzes sales patterns to forecast demand, helping businesses prepare for seasonal peaks or dips.
      - **Barcode and RFID Integration**: Streamlines tracking with barcode and RFID scanning, reducing manual errors and improving speed.
      - **Reporting and Analytics**: Provides insights into sales trends, stock turnover rates, and inventory valuation for informed decision-making.

      **Industry Applications**:
      - **Retail**: Manages stock across multiple locations, preventing stockouts and enabling efficient restocking.
      - **Manufacturing**: Tracks raw materials and components, optimizing production schedules and reducing waste.
      - **Logistics**: Monitors stock levels at warehouses, improving order fulfillment accuracy and delivery times.
      - **Healthcare**: Manages medical supplies, ensuring critical items are always available without excessive overstock.
      - **Automotive**: Tracks parts inventory, reducing delays in repair and maintenance services.
    `,
    industries: ["Retail", "Manufacturing", "Logistics", "Healthcare", "Automotive"]
  },
  {
    title: "Financial Management Systems",
    summary: [
      "Streamlines accounting, budgeting, and financial reporting",
      "Provides insights into company financial health",
      "Ensures compliance with regulatory standards"
    ],
    details: `
      Financial Management Systems provide a comprehensive solution for handling financial data, from accounting to budgeting, ensuring that businesses stay financially healthy and compliant:

      - **General Ledger and Accounting**: Centralizes financial records, simplifying the management of expenses, income, and financial transactions.
      - **Budgeting and Forecasting**: Allows businesses to set budgets, forecast cash flows, and plan for future expenditures.
      - **Accounts Payable and Receivable**: Automates billing and collections processes, reducing overdue invoices and improving cash flow.
      - **Compliance and Audit Trails**: Maintains accurate records with an audit trail, ensuring compliance with financial regulations.
      - **Financial Reporting**: Generates detailed reports, including income statements, balance sheets, and cash flow statements, offering valuable insights into financial performance.

      **Industry Applications**:
      - **Finance**: Provides secure and compliant financial reporting tools for banks, investment firms, and insurance companies.
      - **Manufacturing**: Manages costs related to raw materials, production, and distribution, helping maintain profitability.
      - **Retail**: Tracks revenue, operating costs, and profit margins across locations.
      - **Healthcare**: Monitors costs associated with patient care, ensuring sustainable operations.
      - **Education**: Manages budgets for departments, grants, and donations, ensuring funds are allocated properly.
    `,
    industries: ["Finance", "Manufacturing", "Retail", "Healthcare", "Education"]
  },
  {
    title: "Marketing Automation Systems",
    summary: [
      "Automates marketing campaigns and social media posting",
      "Enhances customer engagement and lead nurturing",
      "Analyzes campaign performance for data-driven decisions"
    ],
    details: `
      Marketing Automation Systems enable businesses to engage customers and nurture leads at scale by automating various marketing activities, from email campaigns to social media posts:

      - **Email Marketing Automation**: Sends personalized, triggered emails to customers based on their behavior, keeping them engaged without manual intervention.
      - **Lead Scoring and Nurturing**: Identifies high-potential leads and nurtures them with targeted content to increase conversion rates.
      - **Social Media Management**: Schedules and posts content across social platforms, saving time and ensuring consistent brand presence.
      - **Customer Segmentation**: Divides audiences into segments for tailored marketing campaigns that resonate with specific groups.
      - **Campaign Analytics**: Tracks campaign performance, providing insights into metrics such as open rates, click-through rates, and conversions for optimization.

      **Industry Applications**:
      - **Retail**: Runs promotional campaigns and personalized offers based on customer purchasing behavior.
      - **Technology**: Manages product launches, nurturing leads through informative content and demos.
      - **Finance**: Promotes financial products, providing personalized advice and insights to clients.
      - **Education**: Engages prospective students with targeted email campaigns, keeping them informed about application deadlines and events.
      - **Healthcare**: Informs patients about services, wellness tips, and appointment reminders.
    `,
    industries: ["Retail", "Technology", "Finance", "Education", "Healthcare"]
  },
  {
    title: "Learning Management Systems (LMS)",
    summary: [
      "Delivers and tracks employee or student training",
      "Facilitates structured learning and skill development",
      "Offers assessments and reporting tools"
    ],
    details: `
      Learning Management Systems are designed to deliver, track, and manage training and educational content for employees, students, or customers, supporting both compliance and skill development:

      - **Course Creation and Management**: Provides tools for creating structured courses, including multimedia content, quizzes, and assessments.
      - **Progress Tracking**: Monitors learner progress, ensuring that employees or students meet learning milestones.
      - **Assessments and Certification**: Offers quizzes and tests to assess learning, with options for certification upon completion.
      - **Reporting and Analytics**: Tracks learner engagement and performance, providing insights to improve training effectiveness.
      - **Customization and Branding**: Customizes the learning experience to reflect organizational branding and specific learning paths.

      **Industry Applications**:
      - **Education**: Provides online courses and training for schools, universities, and private education providers.
      - **Corporate Training**: Delivers onboarding, compliance, and professional development training for employees.
      - **Healthcare**: Trains medical staff on procedures, compliance, and safety protocols.
      - **Technology**: Provides skill development in software, cybersecurity, and other tech-related fields.
      - **Finance**: Trains employees on regulations, compliance, and ethical practices in financial management.
    `,
    industries: ["Education", "Corporate Training", "Healthcare", "Technology", "Finance"]
  },
  {
    title: "Content Management Systems (CMS)",
    summary: [
      "Effortlessly create, organize, and update website content",
      "Streamline content management without relying on developers",
      "Maintain consistent branding and user experience across all content"
    ],
    details: `
      Content Management Systems (CMS) empower businesses to manage their digital content efficiently. By providing user-friendly tools, CMS platforms allow teams to update content quickly without requiring technical expertise, making it ideal for organizations with frequent content updates:

      - **Content Creation and Editing**: Easily create, edit, and format text, images, and videos, ensuring your content is always fresh and relevant.
      - **User Roles and Permissions**: Define access levels, allowing different team members to manage specific parts of the site, maintaining security and content integrity.
      - **Templates and Themes**: Use customizable templates to maintain brand consistency while reducing design time.
      - **SEO Tools**: Built-in SEO features optimize content for search engines, improving site visibility and driving traffic.
      - **Analytics and Reporting**: Track content performance to understand what resonates with your audience, enabling data-driven decisions.

      **Industry Applications**:
      - **Media**: Streamlines content publishing for news, blogs, and media platforms.
      - **Education**: Facilitates updates to academic resources, course information, and events.
      - **Retail**: Allows frequent updates to product descriptions, promotions, and landing pages.
      - **Healthcare**: Manages patient resources, educational content, and healthcare service updates.
      - **Technology**: Publishes articles, product documentation, and tutorials for a tech-savvy audience.
    `,
    industries: ["Media", "Education", "Retail", "Healthcare", "Technology"]
  },
  {
    title: "Asset Management Systems",
    summary: [
      "Monitor and track asset usage across their lifecycle",
      "Optimize asset utilization to reduce costs",
      "Ensure timely maintenance to extend asset lifespan"
    ],
    details: `
      Asset Management Systems are crucial for tracking and managing physical and digital assets, from initial acquisition to eventual disposal. By having a centralized system, companies can ensure assets are effectively utilized, well-maintained, and accurately accounted for:

      - **Asset Tracking**: Keep a detailed inventory of all assets, including their location, status, and assigned users.
      - **Maintenance Scheduling**: Schedule regular maintenance to prevent unexpected breakdowns and extend asset life.
      - **Depreciation and Cost Management**: Track asset depreciation for financial reporting and budget planning.
      - **Audit and Compliance**: Maintain a transparent record for audits, helping with regulatory compliance and internal accountability.
      - **Usage Analytics**: Generate insights on asset utilization, helping to optimize resource allocation and reduce wastage.

      **Industry Applications**:
      - **Manufacturing**: Tracks machinery and equipment to minimize downtime and maximize production.
      - **Healthcare**: Manages medical equipment, ensuring availability and compliance with healthcare regulations.
      - **Finance**: Keeps track of office assets and IT equipment for cost allocation and budgeting.
      - **Education**: Manages school or university resources such as computers, lab equipment, and facilities.
      - **Government**: Tracks assets across departments for operational efficiency and public accountability.
    `,
    industries: ["Manufacturing", "Healthcare", "Finance", "Education", "Government"]
  },
  {
    title: "Sales Force Automation (SFA)",
    summary: [
      "Automates sales tasks, enhancing productivity",
      "Improves customer relationship management and lead tracking",
      "Provides insights into sales performance and opportunities"
    ],
    details: `
      Sales Force Automation (SFA) systems are designed to streamline and automate various aspects of the sales process, from lead generation to customer relationship management, enabling sales teams to focus on closing deals and growing revenue:

      - **Lead Management**: Captures and tracks leads through the sales funnel, helping teams prioritize high-value prospects.
      - **Contact and Account Management**: Centralizes customer data, allowing teams to view and manage interactions, preferences, and history.
      - **Sales Forecasting and Reporting**: Provides real-time sales data and forecasts to guide decision-making and goal setting.
      - **Order Processing and Tracking**: Automates order creation, tracking, and invoicing, reducing administrative tasks for the sales team.
      - **Mobile Access**: Allows sales representatives to access CRM data and updates on the go, enhancing productivity during client meetings.

      **Industry Applications**:
      - **Retail**: Manages customer interactions and drives loyalty with personalized service.
      - **Finance**: Tracks client interactions and supports relationship-building efforts.
      - **Technology**: Manages complex sales cycles for software or hardware solutions.
      - **Healthcare**: Helps medical device companies track sales and manage relationships with healthcare providers.
      - **Real Estate**: Organizes property listings, client interactions, and follow-ups, aiding agents in closing deals.
    `,
    industries: ["Retail", "Finance", "Technology", "Healthcare", "Real Estate"]
  },
  {
    title: "Vendor Management Systems",
    summary: [
      "Streamlines procurement and vendor interactions",
      "Ensures compliance and reduces procurement risks",
      "Enhances visibility into vendor performance and costs"
    ],
    details: `
      Vendor Management Systems (VMS) enable organizations to manage their relationships with vendors efficiently, covering everything from procurement and invoicing to vendor performance and compliance. By consolidating vendor data and processes, businesses can optimize their supply chain and reduce risks:

      - **Vendor Selection and Onboarding**: Facilitates vendor selection and qualification, ensuring compliance with company standards.
      - **Contract Management**: Centralizes contract storage, tracking contract terms, renewal dates, and compliance requirements.
      - **Performance Monitoring**: Tracks vendor performance against predefined metrics, helping to identify reliable vendors and address issues proactively.
      - **Invoice and Payment Processing**: Simplifies invoicing and payment workflows, reducing delays and minimizing errors.
      - **Risk Management**: Assesses vendor risk by tracking factors such as financial stability, compliance with regulations, and delivery reliability.

      **Industry Applications**:
      - **Manufacturing**: Manages suppliers of raw materials, components, and other resources critical to production.
      - **Retail**: Coordinates with product suppliers, ensuring timely inventory replenishment and compliance with standards.
      - **Healthcare**: Manages pharmaceutical suppliers and medical equipment vendors, ensuring compliance with health and safety regulations.
      - **Finance**: Monitors vendors providing financial products or services, ensuring compliance and risk mitigation.
      - **Technology**: Manages IT service providers and hardware suppliers, optimizing technology acquisition and support processes.
    `,
    industries: ["Manufacturing", "Retail", "Healthcare", "Finance", "Technology"]
  },
  {
    title: "Risk Management Systems",
    summary: [
      "Identify and evaluate organizational risks proactively",
      "Ensure stability and compliance with regulatory standards",
      "Develop risk mitigation strategies to protect assets and reputation"
    ],
    details: `
      Risk Management Systems help organizations manage potential risks that could impact business operations, regulatory compliance, or financial stability. These systems provide tools to identify, evaluate, and prioritize risks, enabling teams to respond effectively and maintain business continuity:

      - **Risk Identification and Assessment**: Detect potential risks in operations, financials, and compliance, analyzing their likelihood and impact.
      - **Risk Mitigation Planning**: Develop and implement mitigation strategies to minimize potential disruptions.
      - **Regulatory Compliance**: Ensure adherence to industry standards, protecting the organization from regulatory fines and legal issues.
      - **Risk Monitoring and Reporting**: Continuously monitor risk factors and provide real-time insights to management, supporting proactive decision-making.
      - **Scenario Analysis and Modeling**: Simulate potential risk scenarios to understand their impact and prepare effective response strategies.

      **Industry Applications**:
      - **Finance**: Manages risks in financial transactions, investments, and regulatory compliance.
      - **Healthcare**: Addresses risks in patient safety, data protection, and compliance with health regulations.
      - **Insurance**: Assesses and mitigates risks associated with underwriting and claims processing.
      - **Manufacturing**: Monitors risks related to supply chain disruptions, equipment failures, and regulatory standards.
      - **Government**: Ensures compliance with regulations and manages risks related to public safety and resource allocation.
    `,
    industries: ["Finance", "Healthcare", "Insurance", "Manufacturing", "Government"]
  },
  {
    title: "Payroll Management Systems",
    summary: [
      "Automate payroll processing to save time and reduce errors",
      "Calculate taxes, deductions, and benefits accurately",
      "Provide employees easy access to payroll information"
    ],
    details: `
      Payroll Management Systems streamline the payroll process, automating tasks related to employee compensation, tax deductions, and benefits administration. These systems improve accuracy, reduce administrative workload, and ensure compliance with tax and labor laws:

      - **Automated Payroll Processing**: Calculate salaries, wages, bonuses, and deductions automatically, reducing human errors.
      - **Tax Compliance**: Ensure accurate tax calculations and reporting, simplifying compliance with federal, state, and local tax regulations.
      - **Direct Deposit and Payment Options**: Facilitate direct deposit, paper checks, or other payment methods, improving payroll flexibility.
      - **Employee Self-Service Portal**: Allow employees to access their pay stubs, tax forms, and benefits information, enhancing transparency and convenience.
      - **Reporting and Analytics**: Generate reports on payroll expenses, labor costs, and other metrics to aid financial planning.

      **Industry Applications**:
      - **Finance**: Ensures accurate payroll processing, enhancing financial transparency and compliance.
      - **Healthcare**: Manages payroll for various healthcare roles, including nurses, physicians, and administrative staff.
      - **Manufacturing**: Automates payroll for both hourly and salaried employees, handling complex shift differentials.
      - **Education**: Manages payroll for academic staff, support personnel, and seasonal employees.
      - **Technology**: Streamlines payroll processing for tech companies with complex compensation structures.
    `,
    industries: ["Finance", "Healthcare", "Manufacturing", "Education", "Technology"]
  },
  {
    title: "Compliance Management Systems",
    summary: [
      "Monitor and enforce regulatory compliance",
      "Reduce risk of non-compliance penalties",
      "Centralize compliance policies and documentation"
    ],
    details: `
      Compliance Management Systems support organizations in maintaining compliance with internal policies and external regulations, minimizing the risk of penalties and ensuring legal and ethical operations:

      - **Policy Management**: Centralize compliance policies, making them easily accessible to employees and stakeholders.
      - **Audit and Monitoring**: Conduct regular compliance audits and monitor for adherence to regulatory standards.
      - **Regulatory Tracking**: Stay updated with evolving regulations in your industry to ensure continuous compliance.
      - **Incident Reporting and Resolution**: Enable teams to report and address compliance violations promptly, reducing the impact on the organization.
      - **Compliance Training**: Provide employees with training modules to ensure they understand compliance requirements and best practices.

      **Industry Applications**:
      - **Finance**: Ensures adherence to financial regulations like SOX and AML compliance, reducing legal risks.
      - **Healthcare**: Manages compliance with health regulations, including HIPAA, ensuring patient data protection.
      - **Legal**: Assists law firms in adhering to legal standards and ethical guidelines.
      - **Government**: Ensures that governmental agencies follow internal and external regulatory guidelines.
      - **Pharmaceutical**: Manages compliance with FDA regulations, maintaining high standards in drug development and manufacturing.
    `,
    industries: ["Finance", "Healthcare", "Legal", "Government", "Pharmaceutical"]
  },
  {
    title: "Incident Management Systems",
    summary: [
      "Log, track, and resolve incidents impacting operations",
      "Enhance response times and minimize downtime",
      "Identify root causes to prevent future incidents"
    ],
    details: `
      Incident Management Systems are designed to log, track, and resolve incidents that disrupt business operations. By providing a structured approach to incident resolution, these systems help teams respond quickly, minimize impact, and prevent recurrence:

      - **Incident Logging and Categorization**: Capture incident details, categorize by severity, and assign to appropriate teams for resolution.
      - **Real-Time Notifications**: Alert relevant teams immediately, allowing for prompt response and mitigation.
      - **Root Cause Analysis**: Investigate incidents to identify root causes, enabling preventative measures.
      - **Resolution Documentation**: Maintain a record of incidents and resolutions to improve future incident response.
      - **Reporting and Analytics**: Analyze incident data to identify patterns, supporting proactive measures and continuous improvement.

      **Industry Applications**:
      - **IT**: Manages system outages, software bugs, and cybersecurity incidents, ensuring minimal disruption to services.
      - **Healthcare**: Handles incidents related to patient safety, medical equipment malfunctions, and data security breaches.
      - **Government**: Manages public safety incidents, resource allocation issues, and regulatory breaches.
      - **Manufacturing**: Responds to equipment failures, safety hazards, and supply chain disruptions.
      - **Finance**: Tracks incidents impacting financial services, such as transaction errors or data breaches.
    `,
    industries: ["IT", "Healthcare", "Government", "Manufacturing", "Finance"]
  },
  {
    title: "Environmental Health and Safety (EHS) Systems",
    summary: [
      "Manage environmental health and safety compliance",
      "Promote worker safety through hazard monitoring",
      "Ensure compliance with environmental regulations"
    ],
    details: `
      Environmental Health and Safety (EHS) Systems help organizations manage workplace safety, monitor environmental impacts, and comply with industry regulations. These systems support businesses in creating a safe work environment, reducing risk of accidents, and improving environmental stewardship:

      - **Incident Reporting**: Streamline the process of reporting workplace incidents, hazards, and near-misses, promoting a culture of safety.
      - **Regulatory Compliance**: Track compliance with environmental regulations and health standards, ensuring adherence to safety laws and avoiding fines.
      - **Hazard Assessment**: Identify and evaluate workplace hazards, enabling preventive measures to protect employees.
      - **Waste and Emissions Tracking**: Monitor waste production and emissions to ensure environmentally responsible operations.
      - **Safety Training Management**: Organize and document safety training for employees, ensuring they are equipped to handle hazardous situations.
      - **Health Monitoring**: Track employee health metrics and workplace exposure levels to hazardous materials, supporting occupational health.

      **Industry Applications**:
      - **Manufacturing**: Manages safety protocols for machinery and hazardous materials, ensuring a safer work environment.
      - **Energy**: Tracks emissions and complies with environmental regulations, mitigating environmental impact.
      - **Construction**: Monitors onsite safety and environmental hazards to protect workers and comply with regulations.
      - **Healthcare**: Ensures compliance with health and safety standards, protecting both patients and staff.
      - **Government**: Supports regulatory compliance and public safety in government-run facilities and operations.
    `,
    industries: ["Manufacturing", "Energy", "Construction", "Healthcare", "Government"]
  },
  {
    title: "Knowledge Management Systems",
    summary: [
      "Capture and store organizational knowledge",
      "Enhance information sharing across teams",
      "Promote collaboration and learning"
    ],
    details: `
      Knowledge Management Systems enable organizations to capture, store, and share valuable knowledge resources, making it easier for employees to access information and collaborate. By providing a central repository for knowledge, these systems improve efficiency and support continuous learning:

      - **Centralized Knowledge Repository**: Store critical documents, guides, and best practices in one accessible location, reducing information silos.
      - **Searchable Knowledge Base**: Allow employees to quickly search for information, saving time and improving productivity.
      - **Collaboration Tools**: Foster collaboration through shared workspaces and document editing, encouraging team-based problem solving.
      - **Content Curation**: Organize knowledge by relevance, making it easier for employees to find useful information and stay updated on industry trends.
      - **Expert Identification**: Identify subject matter experts within the organization to help others access specialized knowledge.

      **Industry Applications**:
      - **Education**: Supports educators and administrators in accessing curriculum resources and academic research.
      - **Technology**: Centralizes technical documentation, enabling developers and engineers to find solutions quickly.
      - **Consulting**: Organizes case studies and frameworks for consultants, facilitating knowledge transfer within teams.
      - **Healthcare**: Centralizes medical protocols and case studies to enhance patient care and streamline procedures.
      - **Corporate Training**: Provides a hub for training materials and skill development resources, enhancing workforce learning.
    `,
    industries: ["Education", "Technology", "Consulting", "Healthcare", "Corporate Training"]
  },
  {
    title: "Time Tracking Systems",
    summary: [
      "Track employee hours and attendance",
      "Enhance productivity monitoring",
      "Streamline payroll processing with accurate data"
    ],
    details: `
      Time Tracking Systems allow organizations to monitor employee attendance, working hours, and productivity across teams. These systems simplify payroll, improve project time management, and support workforce accountability:

      - **Automated Time Logging**: Enable employees to clock in and out electronically, ensuring accurate timekeeping.
      - **Overtime Calculation**: Track overtime hours automatically, simplifying payroll calculations and ensuring compliance with labor laws.
      - **Productivity Tracking**: Monitor how time is spent on different tasks, providing insights into employee productivity.
      - **Absence Management**: Track employee absences, including vacation and sick days, and streamline leave management processes.
      - **Integration with Payroll Systems**: Integrate with payroll systems for accurate wage calculations based on hours worked, reducing errors in paychecks.

      **Industry Applications**:
      - **Retail**: Tracks shift hours for part-time and full-time employees, streamlining payroll.
      - **Manufacturing**: Manages time for shift-based workers, ensuring proper allocation of labor resources.
      - **Healthcare**: Monitors hours for healthcare professionals working varied shifts, supporting payroll accuracy.
      - **Technology**: Tracks project hours for developers, helping project managers meet deadlines.
      - **Finance**: Manages time and attendance records for employees, enhancing productivity tracking.
    `,
    industries: ["Retail", "Manufacturing", "Healthcare", "Technology", "Finance"]
  },
  {
    title: "Procurement Management Systems",
    summary: [
      "Automate procurement processes for efficiency",
      "Track vendor performance and contract compliance",
      "Optimize purchasing decisions to save costs"
    ],
    details: `
      Procurement Management Systems automate the procurement process, enabling organizations to manage vendor selection, purchase orders, and contract compliance efficiently. These systems streamline sourcing, improve cost control, and ensure transparency in procurement activities:

      - **Purchase Order Management**: Create, track, and manage purchase orders electronically, improving order accuracy and reducing delays.
      - **Vendor Selection and Evaluation**: Evaluate vendor performance to ensure quality and cost-effectiveness in sourcing decisions.
      - **Contract Compliance**: Monitor contract terms and compliance with suppliers, mitigating risk and ensuring adherence to agreements.
      - **Inventory Integration**: Integrate with inventory systems to track stock levels and automate reordering, preventing stockouts.
      - **Cost Control and Budgeting**: Track spending against budgets, enabling better financial planning and procurement cost savings.

      **Industry Applications**:
      - **Manufacturing**: Streamlines raw material sourcing and vendor relationships, ensuring timely delivery of parts.
      - **Finance**: Manages purchasing decisions for office supplies, services, and technology, optimizing expenditures.
      - **Retail**: Handles supplier relationships and stock replenishment, improving inventory management.
      - **Construction**: Procures materials, equipment, and services for projects, ensuring project deadlines are met.
      - **Healthcare**: Sources medical supplies and equipment, ensuring hospitals and clinics maintain necessary stock levels.
    `,
    industries: ["Manufacturing", "Finance", "Retail", "Construction", "Healthcare"]
  },
  {
    title: "Resource Management Systems",
    summary: [
      "Optimize resource allocation across projects",
      "Track resource availability and usage",
      "Reduce costs and prevent resource conflicts"
    ],
    details: `
      Resource Management Systems enable organizations to allocate and manage resources such as personnel, equipment, and facilities effectively. These systems help in tracking resource availability, utilization, and scheduling, ensuring that resources are optimally used without conflicts:

      - **Resource Allocation**: Allocate resources based on project requirements and priorities, ensuring that the right resources are available at the right time.
      - **Capacity Planning**: Forecast resource needs and prevent overallocation, balancing workload across teams.
      - **Real-Time Tracking**: Monitor resource usage in real-time, providing visibility into resource allocation and availability.
      - **Cost Management**: Track costs associated with resource usage, helping to identify savings opportunities and reduce overall expenses.
      - **Conflict Resolution**: Identify and address scheduling conflicts, avoiding project delays caused by resource constraints.

      **Industry Applications**:
      - **Construction**: Manages equipment and labor across multiple job sites, preventing overuse and ensuring timely project completion.
      - **Consulting**: Allocates consultants to projects based on availability and expertise, optimizing team utilization.
      - **Technology**: Balances resources across development projects, ensuring optimal productivity and timely delivery.
      - **Finance**: Schedules financial analysts across projects, maximizing efficiency and cost-effectiveness.
      - **Education**: Allocates classroom spaces and academic staff, ensuring efficient resource use and preventing scheduling conflicts.
    `,
    industries: ["Construction", "Consulting", "Technology", "Finance", "Education"]
  },
  {
    title: "Visitor Management Systems",
    summary: [
      "Streamline visitor check-ins and tracking",
      "Enhance facility security and access control",
      "Maintain accurate visitor records"
    ],
    details: `
      Visitor Management Systems allow organizations to efficiently manage and track visitors entering and exiting their facilities. These systems enhance security, improve visitor experience, and provide accurate records for compliance and safety:

      - **Digital Check-In**: Replace paper sign-ins with a digital check-in process, speeding up visitor entry and improving accuracy.
      - **Visitor Badges**: Print visitor badges with photos and visit details, ensuring easy identification within the premises.
      - **Access Control Integration**: Integrate with access control systems to manage visitor access to specific areas, enhancing security.
      - **Real-Time Monitoring**: Track visitor movements in real-time, allowing quick response to any security concerns.
      - **Reporting and Compliance**: Generate reports for compliance, including detailed visitor logs that meet regulatory requirements.

      **Industry Applications**:
      - **Healthcare**: Tracks visitor access to sensitive areas, ensuring patient privacy and security.
      - **Education**: Monitors visitor entry, enhancing student safety and maintaining secure school environments.
      - **Corporate**: Controls visitor access to offices, protecting sensitive data and intellectual property.
      - **Government**: Ensures secure visitor access to public buildings, maintaining compliance with security standards.
      - **Hospitality**: Manages guest check-ins efficiently, enhancing the overall visitor experience.
    `,
    industries: ["Healthcare", "Education", "Corporate", "Government", "Hospitality"]
  },
  {
    title: "Learning Experience Platforms (LXP)",
    summary: [
      "Personalize employee learning with AI",
      "Enhance engagement with interactive content",
      "Track skill development and learning progress"
    ],
    details: `
      Learning Experience Platforms (LXP) provide AI-powered learning recommendations and personalized content to help employees develop skills and knowledge in an engaging, interactive way. These platforms go beyond traditional Learning Management Systems by focusing on learner engagement and experience:

      - **Personalized Learning Paths**: Use AI to recommend courses and resources based on individual needs, enhancing relevance and engagement.
      - **Interactive Content**: Offer diverse content formats such as videos, quizzes, and simulations, making learning more engaging.
      - **Social Learning**: Facilitate collaboration through discussion forums, peer reviews, and knowledge sharing.
      - **Progress Tracking**: Monitor learning progress and skill acquisition, providing insights into employee development.
      - **Mobile Accessibility**: Access learning materials on mobile devices, enabling learning anytime, anywhere.

      **Industry Applications**:
      - **Education**: Supports student-centered learning with personalized content tailored to individual strengths and weaknesses.
      - **Corporate Training**: Enhances employee training programs, focusing on skill development and knowledge retention.
      - **Healthcare**: Provides specialized training for healthcare professionals, ensuring up-to-date knowledge in patient care.
      - **Technology**: Offers continuous learning for developers, enabling skill growth in a rapidly changing industry.
      - **Finance**: Delivers compliance training and professional development in areas such as financial regulations and risk management.
    `,
    industries: ["Education", "Corporate Training", "Healthcare", "Technology", "Finance"]
  },
  {
    title: "Supplier Relationship Management (SRM) Systems",
    summary: [
      "Streamline supplier interactions and contracts",
      "Monitor supplier performance and quality",
      "Strengthen supply chain relationships"
    ],
    details: `
      Supplier Relationship Management (SRM) Systems help organizations manage interactions, contracts, and performance with suppliers, strengthening the supply chain and ensuring consistent quality. These systems streamline procurement, improve supplier performance, and enhance collaboration:

      - **Supplier Database**: Maintain a centralized database of suppliers, with details on pricing, contracts, and performance history.
      - **Performance Monitoring**: Track supplier performance metrics such as quality, on-time delivery, and compliance with standards.
      - **Contract Management**: Manage supplier contracts, including renewal alerts, compliance tracking, and terms negotiation.
      - **Risk Assessment**: Identify and mitigate risks in the supply chain, ensuring business continuity.
      - **Collaboration Tools**: Enhance communication and collaboration with suppliers, fostering stronger, mutually beneficial relationships.

      **Industry Applications**:
      - **Manufacturing**: Ensures quality materials from suppliers, supporting consistent production.
      - **Retail**: Manages supplier relationships for inventory sourcing, ensuring availability of products.
      - **Construction**: Tracks suppliers for raw materials, enhancing project timelines and quality.
      - **Technology**: Manages components and software suppliers, ensuring reliable sourcing and high-quality output.
      - **Healthcare**: Sources medical supplies and equipment from approved suppliers, ensuring compliance with health standards.
    `,
    industries: ["Manufacturing", "Retail", "Construction", "Technology", "Healthcare"]
  },
  {
    title: "Contract Management Systems",
    summary: [
      "Track and manage contracts throughout their lifecycle",
      "Ensure compliance and renewal alerts",
      "Organize contract terms and obligations"
    ],
    details: `
      Contract Management Systems enable organizations to track, manage, and store contracts efficiently, reducing the risk of non-compliance and missed renewals. These systems ensure that contractual obligations are met, streamline workflows, and provide visibility into contract terms:

      - **Centralized Repository**: Store all contracts in a centralized system, ensuring easy access and reducing document silos.
      - **Automated Alerts**: Set renewal and compliance alerts, preventing lapses and ensuring proactive contract management.
      - **Version Control**: Track changes to contract terms, ensuring that the latest versions are always available.
      - **Compliance Monitoring**: Monitor contract compliance to ensure that all terms are met and that parties adhere to agreed-upon obligations.
      - **Reporting and Analytics**: Generate reports on contract status, compliance, and financial obligations, supporting informed decision-making.

      **Industry Applications**:
      - **Legal**: Manages client agreements, tracking terms and renewal dates to ensure compliance.
      - **Finance**: Organizes contracts for loans, leases, and investments, ensuring regulatory compliance.
      - **Healthcare**: Manages supplier contracts for medical supplies, maintaining compliance with health standards.
      - **Construction**: Tracks project contracts, ensuring contractors meet milestones and project requirements.
      - **Real Estate**: Manages leases and property contracts, ensuring timely renewals and adherence to legal requirements.
    `,
    industries: ["Legal", "Finance", "Healthcare", "Construction", "Real Estate"]
  },
  {
    title: "Energy Management Systems",
    summary: [
      "Track and reduce energy consumption across facilities",
      "Lower costs through optimized energy use",
      "Support sustainability and environmental goals"
    ],
    details: `
      Energy Management Systems help businesses monitor, manage, and optimize energy usage, resulting in reduced operational costs and environmental impact. These systems promote sustainable practices by identifying areas for energy efficiency:

      - **Energy Monitoring**: Track real-time energy consumption across facilities, providing visibility into energy use patterns.
      - **Cost Analysis**: Analyze energy expenses by location, department, or equipment to identify opportunities for cost savings.
      - **Automated Control**: Implement automated controls for HVAC, lighting, and equipment to reduce energy waste.
      - **Sustainability Tracking**: Monitor carbon emissions and other environmental metrics to support corporate sustainability goals.
      - **Predictive Analytics**: Use historical data to forecast energy needs, helping to prevent peak usage charges.

      **Industry Applications**:
      - **Manufacturing**: Optimizes energy use in production processes, reducing overall operational costs.
      - **Energy**: Tracks renewable energy sources and manages grid distribution, ensuring efficient energy flow.
      - **Corporate**: Reduces utility costs in office buildings by optimizing HVAC and lighting systems.
      - **Government**: Supports energy conservation initiatives across public buildings and infrastructure.
      - **Real Estate**: Enhances building efficiency, reducing energy costs for commercial and residential properties.
    `,
    industries: ["Manufacturing", "Energy", "Corporate", "Government", "Real Estate"]
  },
  {
    title: "Digital Asset Management (DAM) Systems",
    summary: [
      "Organize and manage digital files in one central repository",
      "Ensure consistent branding with easy access to approved assets",
      "Enhance collaboration across teams through shared resources"
    ],
    details: `
      Digital Asset Management (DAM) Systems provide a centralized platform for storing, organizing, and retrieving digital assets such as images, videos, and documents. These systems improve brand consistency and streamline workflows by ensuring that teams can access the latest, approved assets:

      - **Centralized Storage**: Store all digital files in a single repository, making it easy for teams to find and use assets.
      - **Metadata Tagging**: Tag assets with metadata to facilitate searchability, ensuring quick access to relevant files.
      - **Version Control**: Maintain version histories for assets, ensuring that teams use the latest approved versions.
      - **Access Control**: Set permissions to control who can view, download, or edit assets, maintaining security and brand integrity.
      - **Integration with Design Tools**: Seamlessly integrate with creative tools like Adobe Creative Suite, enabling efficient asset use.

      **Industry Applications**:
      - **Media**: Manages large volumes of digital content, ensuring quick access for content creation and distribution.
      - **Marketing**: Supports campaigns by providing easy access to brand assets and promotional materials.
      - **Education**: Organizes educational materials, such as lectures and multimedia resources, for easy access.
      - **Retail**: Ensures consistent branding across digital and print marketing materials.
      - **Technology**: Stores and shares product images, presentations, and design assets within teams.
    `,
    industries: ["Media", "Marketing", "Education", "Retail", "Technology"]
  },
  {
    title: "Inventory Optimization Systems",
    summary: [
      "Balance supply with demand to prevent stockouts and overstocking",
      "Reduce inventory carrying costs through efficient planning",
      "Enhance order fulfillment by maintaining optimal stock levels"
    ],
    details: `
      Inventory Optimization Systems are designed to help businesses maintain the perfect balance between supply and demand, ensuring that inventory levels are just right to meet customer needs without incurring excess costs:

      - **Demand Forecasting**: Predict future demand based on historical data and trends, allowing for more accurate inventory planning.
      - **Reorder Point Calculation**: Set reorder points for each item, ensuring timely replenishment to prevent stockouts.
      - **Cost Reduction**: Minimize storage and carrying costs by avoiding excess inventory, while ensuring sufficient stock to meet demand.
      - **Real-Time Monitoring**: Track inventory levels in real-time, enabling swift adjustments to prevent both shortages and overages.
      - **Supplier Management**: Optimize supplier lead times and order quantities to streamline the supply chain.

      **Industry Applications**:
      - **Retail**: Ensures popular products are always available while preventing overstock of seasonal items.
      - **Manufacturing**: Balances raw material inventory to keep production running smoothly.
      - **Healthcare**: Maintains essential medical supplies, reducing waste of short-lifespan items.
      - **Automotive**: Keeps parts inventory in line with demand, supporting efficient vehicle assembly.
      - **Logistics**: Manages stock across multiple distribution centers, ensuring timely delivery.
    `,
    industries: ["Retail", "Manufacturing", "Healthcare", "Automotive", "Logistics"]
  },
  {
    title: "Maintenance Management Systems",
    summary: [
      "Schedule and organize equipment maintenance",
      "Prevent unexpected downtime with regular servicing",
      "Extend equipment lifespan through optimized upkeep"
    ],
    details: `
      Maintenance Management Systems support organizations in organizing and tracking maintenance activities for equipment and assets. This proactive approach reduces downtime, enhances equipment performance, and extends asset life:

      - **Preventive Maintenance Scheduling**: Automate maintenance schedules to avoid unexpected breakdowns, ensuring operational continuity.
      - **Asset Tracking**: Monitor the location, usage, and condition of assets to prioritize maintenance activities.
      - **Work Order Management**: Create and assign work orders to technicians, tracking completion and any required follow-ups.
      - **Inventory Integration**: Manage spare parts and maintenance materials inventory, ensuring parts are available when needed.
      - **Data Analytics**: Analyze equipment performance data to predict potential failures and optimize maintenance schedules.

      **Industry Applications**:
      - **Manufacturing**: Keeps production equipment operational, reducing costly downtime.
      - **Utilities**: Ensures continuous operation of infrastructure, such as power plants and water treatment facilities.
      - **Healthcare**: Manages maintenance of medical devices and critical equipment, ensuring safety and compliance.
      - **Construction**: Tracks and maintains heavy machinery to ensure safe and efficient use on job sites.
      - **Logistics**: Ensures fleet vehicles are well-maintained, minimizing delays in transportation services.
    `,
    industries: ["Manufacturing", "Utilities", "Healthcare", "Construction", "Logistics"]
  },
  {
    title: "Energy Management Systems",
    summary: [
      "Track and reduce energy consumption across facilities",
      "Lower costs through optimized energy use",
      "Support sustainability and environmental goals"
    ],
    details: `
      Energy Management Systems help businesses monitor, manage, and optimize energy usage, resulting in reduced operational costs and environmental impact. These systems promote sustainable practices by identifying areas for energy efficiency:

      - **Energy Monitoring**: Track real-time energy consumption across facilities, providing visibility into energy use patterns.
      - **Cost Analysis**: Analyze energy expenses by location, department, or equipment to identify opportunities for cost savings.
      - **Automated Control**: Implement automated controls for HVAC, lighting, and equipment to reduce energy waste.
      - **Sustainability Tracking**: Monitor carbon emissions and other environmental metrics to support corporate sustainability goals.
      - **Predictive Analytics**: Use historical data to forecast energy needs, helping to prevent peak usage charges.

      **Industry Applications**:
      - **Manufacturing**: Optimizes energy use in production processes, reducing overall operational costs.
      - **Energy**: Tracks renewable energy sources and manages grid distribution, ensuring efficient energy flow.
      - **Corporate**: Reduces utility costs in office buildings by optimizing HVAC and lighting systems.
      - **Government**: Supports energy conservation initiatives across public buildings and infrastructure.
      - **Real Estate**: Enhances building efficiency, reducing energy costs for commercial and residential properties.
    `,
    industries: ["Manufacturing", "Energy", "Corporate", "Government", "Real Estate"]
  },
  {
    title: "Digital Asset Management (DAM) Systems",
    summary: [
      "Organize and manage digital files in one central repository",
      "Ensure consistent branding with easy access to approved assets",
      "Enhance collaboration across teams through shared resources"
    ],
    details: `
      Digital Asset Management (DAM) Systems provide a centralized platform for storing, organizing, and retrieving digital assets such as images, videos, and documents. These systems improve brand consistency and streamline workflows by ensuring that teams can access the latest, approved assets:

      - **Centralized Storage**: Store all digital files in a single repository, making it easy for teams to find and use assets.
      - **Metadata Tagging**: Tag assets with metadata to facilitate searchability, ensuring quick access to relevant files.
      - **Version Control**: Maintain version histories for assets, ensuring that teams use the latest approved versions.
      - **Access Control**: Set permissions to control who can view, download, or edit assets, maintaining security and brand integrity.
      - **Integration with Design Tools**: Seamlessly integrate with creative tools like Adobe Creative Suite, enabling efficient asset use.

      **Industry Applications**:
      - **Media**: Manages large volumes of digital content, ensuring quick access for content creation and distribution.
      - **Marketing**: Supports campaigns by providing easy access to brand assets and promotional materials.
      - **Education**: Organizes educational materials, such as lectures and multimedia resources, for easy access.
      - **Retail**: Ensures consistent branding across digital and print marketing materials.
      - **Technology**: Stores and shares product images, presentations, and design assets within teams.
    `,
    industries: ["Media", "Marketing", "Education", "Retail", "Technology"]
  },
  {
    title: "Case Management Systems",
    summary: [
      "Track and manage cases from initiation to resolution",
      "Improve visibility and accountability in case handling",
      "Streamline case workflows for better efficiency"
    ],
    details: `
      Case Management Systems enable organizations to efficiently handle cases, from incident reporting to resolution. These systems improve transparency, accountability, and efficiency, making it easier to manage and track cases:

      - **Centralized Case Tracking**: Maintain all case information in one place, improving visibility and facilitating quick access to details.
      - **Workflow Automation**: Automate case workflows, ensuring cases progress through the necessary steps without delays.
      - **Document Management**: Attach documents, emails, and other records to cases, keeping all related materials together.
      - **Role-Based Access**: Control access to cases based on roles, ensuring data security and privacy.
      - **Analytics and Reporting**: Generate reports to analyze case trends, providing insights into potential areas for improvement.

      **Industry Applications**:
      - **Legal**: Manages legal cases, tracking status and progress from filing through resolution.
      - **Healthcare**: Tracks patient cases and treatment plans, ensuring continuity of care.
      - **Insurance**: Manages claims from submission to settlement, improving customer satisfaction and compliance.
      - **Finance**: Handles customer complaints and disputes, providing clear documentation and tracking for resolution.
      - **Government**: Manages cases related to public services, ensuring accountability and timely resolution.
    `,
    industries: ["Legal", "Healthcare", "Insurance", "Finance", "Government"]
  }
];

const industries = [
  "Manufacturing", "Retail", "Healthcare", "Finance", "Logistics", "Education", 
  "Technology", "Construction", "Consulting", "Real Estate", "Hospitality", "Legal",
  "Telecommunications", "Fashion", "Electronics", "Food & Beverage", "Automotive", 
  "Media", "Government", "Corporate Training",
];

const ServicesPage = () => {
  const [activeService, setActiveService] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("All");

  const navigate = useNavigate();

  const handleSearch = (event) => setSearchTerm(event.target.value.toLowerCase());
  const handleIndustryChange = (event) => setSelectedIndustry(event.target.value);
  const openService = (service) => setActiveService(service);
  const closeService = () => setActiveService(null);
  const navigateToConsultationRequest = () => navigate("/request-consultation");

  const filteredServices = servicesData.filter((service) => {
    const matchesSearch = service.title.toLowerCase().includes(searchTerm);
    const matchesIndustry = selectedIndustry === "All" || service.industries.includes(selectedIndustry);
    return matchesSearch && matchesIndustry;
  });

  return (
    <div className="services-page">
      <HeaderBar />
      <section className="content-container">
        <h1>Our Elite Business Systems Solutions</h1>
        
        <div className="filters">
          <input
            type="text"
            placeholder="Search services..."
            onChange={handleSearch}
            className="search-bar"
          />
          <select onChange={handleIndustryChange} className="industry-dropdown">
            <option value="All">All Industries</option>
            {industries.map((industry, index) => (
              <option key={index} value={industry}>{industry}</option>
            ))}
          </select>
        </div>

        <div className="service-cards">
          {filteredServices.map((service, index) => (
            <div
              key={index}
              className="service-card"
              onClick={() => openService(service)}
            >
              <h3>{service.title}</h3>
              <button className="learn-more-btn" onClick={() => openService(service)}>
                Learn More
              </button>
            </div>
          ))}
        </div>

        {activeService && (
          <div className="service-popup">
            <div className="service-popup-content">
              <button className="close-btn" onClick={closeService}>X</button>
              <h3>{activeService.title}</h3>
              <p>{activeService.details}</p>
              <p className="industries"><strong>Industries:</strong> {activeService.industries.join(', ')}</p>
              <button className="consultation-btn" onClick={navigateToConsultationRequest}>
                Request Consultation
              </button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default ServicesPage;