import React, { useEffect, useState } from 'react';
import { auth, db } from '../../../firebase/firebaseConfig';
import { collection, getDocs, addDoc, updateDoc, doc, query, where } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import './SalesProjectsOverview.css';
import SalesSidebar from './SalesSidebar';
import { FaPlus } from 'react-icons/fa';
import { startOfMonth, addMonths, isWithinInterval } from 'date-fns';

function SalesProjectsOverview() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [milestones, setMilestones] = useState([{ title: '', description: '', dueDate: '', status: 'Not Started' }]);
  const [newProject, setNewProject] = useState({
    name: '',
    description: '',
    clientId: '',
    salesRep: '',
    dueDate: '',
    notes: '',
    status: 'Not Started',
    milestones: [],
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [filterRange, setFilterRange] = useState('');
  const [loading, setLoading] = useState(true);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchData = async (userId) => {
      try {
        setLoading(true);

        const projectsQuery = query(
          collection(db, 'Projects'),
          where('salesRep', '==', userId)
        );
        const projectSnapshot = await getDocs(projectsQuery);
        const projectList = projectSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setProjects(projectList);
        setFilteredProjects(projectList);

        const clientSnapshot = await getDocs(collection(db, 'Clients'));
        const clientList = clientSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setClients(clientList);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchData(user.uid);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleMilestoneChange = (index, e) => {
    const { name, value } = e.target;
    const updatedMilestones = milestones.map((milestone, i) =>
      i === index ? { ...milestone, [name]: value } : milestone
    );
    setMilestones(updatedMilestones);
  };

  const addMilestone = () => {
    setMilestones([...milestones, { title: '', description: '', dueDate: '', status: 'Not Started' }]);
  };

  const removeMilestone = (index) => {
    setMilestones(milestones.filter((_, i) => i !== index));
  };

  const handleAddProject = async () => {
    if (!currentUser) return;

    try {
      const projectData = {
        ...newProject,
        salesRep: currentUser.uid,
        milestones,
        dueDate: newProject.dueDate ? new Date(newProject.dueDate) : null,
      };
      const docRef = await addDoc(collection(db, 'Projects'), projectData);
      setProjects((prevProjects) => [...prevProjects, { id: docRef.id, ...projectData }]);
      setFilteredProjects((prevProjects) => [...prevProjects, { id: docRef.id, ...projectData }]);
      setNewProject({
        name: '',
        description: '',
        clientId: '',
        dueDate: '',
        notes: '',
        status: 'Not Started',
        milestones: [],
      });
      setMilestones([{ title: '', description: '', dueDate: '', status: 'Not Started' }]);
      setIsAddProjectModalOpen(false);
    } catch (error) {
      console.error('Error adding project:', error);
      alert('Failed to add project. Please try again.');
    }
  };

  const calculateProgress = (project) => {
    const completedMilestones = project.milestones.filter(milestone => milestone.status === 'Completed').length;
    const totalMilestones = project.milestones.length;
    return totalMilestones ? (completedMilestones / totalMilestones) * 100 : 0;
  };

  const openProjectDetailModal = (project) => {
    setSelectedProject(project);
    setIsProjectModalOpen(true);
  };

  const handleStatusChange = async (projectId, milestoneIndex, status) => {
    const updatedProjects = projects.map((project) => {
      if (project.id === projectId) {
        const updatedMilestones = project.milestones.map((milestone, index) =>
          index === milestoneIndex ? { ...milestone, status } : milestone
        );
        return { ...project, milestones: updatedMilestones };
      }
      return project;
    });

    setProjects(updatedProjects);
    setFilteredProjects(updatedProjects);

    const projectDoc = doc(db, 'Projects', projectId);
    await updateDoc(projectDoc, { milestones: updatedProjects.find((p) => p.id === projectId).milestones });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    filterProjects(e.target.value, filterRange);
  };

  const handleFilterRange = (e) => {
    setFilterRange(e.target.value);
    filterProjects(searchQuery, e.target.value);
  };

  const filterProjects = (query, range) => {
    let filtered = projects;

    if (query) {
      filtered = filtered.filter(
        (project) =>
          project.name.toLowerCase().includes(query.toLowerCase()) ||
          project.description.toLowerCase().includes(query.toLowerCase())
      );
    }

    const now = new Date();

    if (range) {
      switch (range) {
        case 'thisMonth':
          filtered = filtered.filter((project) =>
            project.dueDate && isWithinInterval(project.dueDate.toDate(), {
              start: startOfMonth(now),
              end: addMonths(startOfMonth(now), 1),
            })
          );
          break;
        case 'nextMonth':
          filtered = filtered.filter((project) =>
            project.dueDate && isWithinInterval(project.dueDate.toDate(), {
              start: addMonths(startOfMonth(now), 1),
              end: addMonths(startOfMonth(now), 2),
            })
          );
          break;
        case 'within3Months':
          filtered = filtered.filter((project) =>
            project.dueDate && isWithinInterval(project.dueDate.toDate(), {
              start: now,
              end: addMonths(now, 3),
            })
          );
          break;
        case 'beyond3Months':
          filtered = filtered.filter((project) =>
            project.dueDate && project.dueDate.toDate() > addMonths(now, 3)
          );
          break;
        default:
          break;
      }
    }

    setFilteredProjects(filtered);
  };

  if (loading) return <div>Loading project data...</div>;

  return (
    <div className="sales-projects-container">
      <SalesSidebar />
      <div className="projects-content">
        <h2>Your Projects</h2>

        {/* Search and Filter */}
        <div className="search-filter">
          <input
            type="text"
            placeholder="Search projects..."
            value={searchQuery}
            onChange={handleSearch}
          />
          <select value={filterRange} onChange={handleFilterRange}>
            <option value="">Select Timeframe</option>
            <option value="thisMonth">Due This Month</option>
            <option value="nextMonth">Due Next Month</option>
            <option value="within3Months">Due Within 3 Months</option>
            <option value="beyond3Months">Due Beyond 3 Months</option>
          </select>
        </div>

        {/* Summary Boxes */}
        <div className="summary-boxes">
          <div className="summary-box">Total Projects: {filteredProjects.length}</div>
          <div className="summary-box">Completed Projects: {filteredProjects.filter(p => calculateProgress(p) === 100).length}</div>
          <div className="summary-box">In Progress: {filteredProjects.filter(p => calculateProgress(p) > 0 && calculateProgress(p) < 100).length}</div>
        </div>

        {/* Project List */}
        <div className="project-list">
          {filteredProjects.map((project) => (
            <div key={project.id} className="project-item" onClick={() => openProjectDetailModal(project)}>
              <h3>{project.name}</h3>
              <p>{project.description}</p>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width: `${calculateProgress(project)}%` }}
                ></div>
              </div>
              <p>Progress: {calculateProgress(project).toFixed(2)}%</p>
            </div>
          ))}
        </div>

        {/* Floating Plus Button */}
        <button className="floating-plus-button" onClick={() => setIsAddProjectModalOpen(true)}>
          <FaPlus />
        </button>
      </div>

      {/* Add Project Modal */}
      {isAddProjectModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Add New Project</h3>
            <input type="text" name="name" placeholder="Project Name" value={newProject.name} onChange={handleInputChange} />
            <textarea name="description" placeholder="Project Description" value={newProject.description} onChange={handleInputChange} />
            <select name="clientId" value={newProject.clientId} onChange={handleInputChange}>
              <option value="">Select Client</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
            <label>Overall Project Due Date</label>
            <input type="date" name="dueDate" value={newProject.dueDate} onChange={handleInputChange} />
            <textarea name="notes" placeholder="Project Notes" value={newProject.notes} onChange={handleInputChange} />

            <h4>Milestones</h4>
            {milestones.map((milestone, index) => (
              <div key={index} className="milestone-input">
                <input type="text" name="title" placeholder="Milestone Title" value={milestone.title} onChange={(e) => handleMilestoneChange(index, e)} />
                <textarea name="description" placeholder="Milestone Description" value={milestone.description} onChange={(e) => handleMilestoneChange(index, e)} />
                <label>Milestone Deadline</label>
                <input type="date" name="dueDate" value={milestone.dueDate} onChange={(e) => handleMilestoneChange(index, e)} />
                <select name="status" value={milestone.status} onChange={(e) => handleMilestoneChange(index, e)}>
                  <option value="Not Started">Not Started</option>
                  <option value="Started">Started</option>
                  <option value="Completed">Completed</option>
                </select>
                <button onClick={() => removeMilestone(index)} className="remove-milestone-button">Remove Milestone</button>
              </div>
            ))}
            <button onClick={addMilestone}>Add Milestone</button>
            <button onClick={handleAddProject} className="add-button">Add Project</button>
            <button onClick={() => setIsAddProjectModalOpen(false)} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}

      {/* Project Detail Modal */}
      {isProjectModalOpen && selectedProject && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>{selectedProject.name}</h3>
            <p>{selectedProject.description}</p>
            <p>Due Date: {selectedProject.dueDate?.toDate().toLocaleDateString()}</p>
            <p>Status: {selectedProject.status}</p>
            <h4>Milestones</h4>
            {selectedProject.milestones.map((milestone, index) => (
              <div key={index} className="milestone-detail">
                <p>Title: {milestone.title}</p>
                <p>Description: {milestone.description}</p>
                <p>Due Date: {milestone.dueDate}</p>
                <label>Status:</label>
                <select
                  value={milestone.status}
                  onChange={(e) => handleStatusChange(selectedProject.id, index, e.target.value)}
                >
                  <option value="Not Started">Not Started</option>
                  <option value="Started">Started</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            ))}
            <button onClick={() => setIsProjectModalOpen(false)} className="close-button">Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SalesProjectsOverview;
