import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import HeaderBar from './HeaderBar';
import ConsultationRequest from './ConsultationRequest';

function LandingPage() {
  const [showConsultationModal, setShowConsultationModal] = useState(false);
  const openConsultationModal = () => setShowConsultationModal(true);
  const closeConsultationModal = () => setShowConsultationModal(false);

  return (
    <div className="landing-page">
      <HeaderBar />

      <section className="hero">
        <div className="hero-content">
          <h1>AigencyOne Technology</h1>
          <p>Leading the way in business systems consolidation, saving companies time and money with powerful, integrated solutions.</p>
          <div className="buttons">
            <Link to="/quote-tool" className="btn primary-btn">GET A FREE QUOTE</Link>
            <button className="btn secondary-btn" onClick={openConsultationModal}>REQUEST CONSULTATION</button>
          </div>
        </div>
        <div className="hero-video">
          <video autoPlay loop muted playsInline className="background-video">
            <source src="https://storage.googleapis.com/logohostbucket/TechBackground1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <p>&copy; {new Date().getFullYear()} AigencyOne. All rights reserved.</p>
          <div className="social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
          </div>
        </div>
      </footer>

      {showConsultationModal && <ConsultationRequest onClose={closeConsultationModal} />}
    </div>
  );
}

export default LandingPage;
