import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase/firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { updateEmail, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import ClientSidebar from './ClientSidebar';
import styles from './ClientSettings.module.css';

function ClientSettings() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientData = async () => {
      const user = auth.currentUser;
      if (user) {
        const clientDocRef = doc(db, 'Clients', user.uid);
        const clientSnapshot = await getDoc(clientDocRef);
        if (clientSnapshot.exists()) {
          const data = clientSnapshot.data();
          setEmail(data.email || '');
          setName(data.name || '');
          setPhone(data.phone || '');
          setBillingAddress(data.billingAddress || '');
        }
      } else {
        navigate('/login');
      }
    };

    fetchClientData();
  }, [navigate]);

  const handlePasswordVerification = async () => {
    const user = auth.currentUser;
    if (user && currentPassword) {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      try {
        await reauthenticateWithCredential(user, credential);
        setShowPasswordFields(true);
      } catch (error) {
        alert("Incorrect password. Please try again.");
      }
    }
  };

  const handleUpdate = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Update email if changed
        if (email !== user.email) {
          await updateEmail(user, email);
        }
        
        // Update password if provided
        if (newPassword) {
          await updatePassword(user, newPassword);
        }

        // Update Firestore client data
        const clientDocRef = doc(db, 'Clients', user.uid);
        await updateDoc(clientDocRef, {
          name,
          phone,
          billingAddress,
          email,
        });

        alert("Settings updated successfully.");
      } catch (error) {
        alert("Error updating settings: " + error.message);
      }
    }
  };

  return (
    <div className={styles.clientSettings}>
      <ClientSidebar />
      <div className={styles.clientSettingsContent}>
        <h1>Account Settings</h1>

        <section className={styles.settingsSection}>
          <label className={styles.label}>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
          />

          <label className={styles.label}>Phone</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className={styles.input}
          />

          <label className={styles.label}>Billing Address</label>
          <input
            type="text"
            value={billingAddress}
            onChange={(e) => setBillingAddress(e.target.value)}
            className={styles.input}
          />

          <label className={styles.label}>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.input}
          />

          <label className={styles.label}>Current Password</label>
          <input
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className={styles.input}
          />
          <button onClick={handlePasswordVerification} className={styles.verifyButton}>
            Verify Password
          </button>

          {showPasswordFields && (
            <>
              <label className={styles.label}>New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className={styles.input}
              />
            </>
          )}

          <button onClick={handleUpdate} className={styles.updateButton}>
            Save Changes
          </button>
        </section>
      </div>
    </div>
  );
}

export default ClientSettings;
