import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase/firebaseConfig';
import { collection, getDocs, doc, deleteDoc, updateDoc, addDoc } from 'firebase/firestore';
import './ExecutiveProjects.css';
import ExecutiveSidebar from './ExecutiveSidebar';

function ExecutiveProjects() {
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [milestones, setMilestones] = useState([{ title: '', description: '', dueDate: '', status: 'Not Started' }]);
  const [newProject, setNewProject] = useState({
    name: '',
    description: '',
    clientId: '',
    salesRep: '',
    developer: '',
    milestones: [],
    dueDate: '',
    notes: '',
    documents: null,
  });
  const [editProject, setEditProject] = useState(null);
  const [updatedData, setUpdatedData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectSnapshot = await getDocs(collection(db, 'Projects'));
        const clientSnapshot = await getDocs(collection(db, 'Clients'));
        const employeeSnapshot = await getDocs(collection(db, 'Employees'));

        const projectList = projectSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const clientList = clientSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const employeeList = employeeSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        setProjects(projectList || []);
        setClients(clientList || []);
        setEmployees(employeeList || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleMilestoneChange = (index, e) => {
    const { name, value } = e.target;
    const updatedMilestones = milestones.map((milestone, i) =>
      i === index ? { ...milestone, [name]: value } : milestone
    );
    setMilestones(updatedMilestones);
  };

  const addMilestone = () => {
    setMilestones([...milestones, { title: '', description: '', dueDate: '', status: 'Not Started' }]);
  };

  const removeMilestone = (index) => {
    setMilestones(milestones.filter((_, i) => i !== index));
  };

  const handleAddProject = async () => {
    console.log("Adding new project...");
    try {
      const projectData = {
        ...newProject,
        milestones, // Ensure milestones array is stored in Firestore
        dueDate: newProject.dueDate ? new Date(newProject.dueDate) : null,
      };
      console.log("Project data:", projectData);

      const docRef = await addDoc(collection(db, 'Projects'), projectData);
      setProjects((prevProjects) => [...prevProjects, { id: docRef.id, ...projectData }]);

      // Reset form after adding
      setNewProject({
        name: '',
        description: '',
        clientId: '',
        salesRep: '',
        developer: '',
        milestones: [],
        dueDate: '',
        notes: '',
        documents: null,
      });
      setMilestones([{ title: '', description: '', dueDate: '', status: 'Not Started' }]);
      alert('Project added successfully!');
    } catch (error) {
      console.error('Error adding project:', error);
      alert('Failed to add project. Please try again.');
    }
  };

  const handleEditClick = (project) => {
    setEditProject(project);
    setUpdatedData({
      ...project,
      dueDate: project.dueDate?.toDate().toISOString().substring(0, 10) || '',
    });
    setMilestones(project.milestones || []);
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdateSubmit = async () => {
    if (editProject) {
      const projectRef = doc(db, 'Projects', editProject.id);
      try {
        await updateDoc(projectRef, {
          ...updatedData,
          milestones,
          dueDate: new Date(updatedData.dueDate),
        });
        setProjects((prevProjects) =>
          prevProjects.map((proj) => (proj.id === editProject.id ? { ...proj, ...updatedData } : proj))
        );
        setEditProject(null);
        setUpdatedData({});
      } catch (error) {
        console.error('Error updating project:', error);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'Projects', id));
      setProjects((prevProjects) => prevProjects.filter((proj) => proj.id !== id));
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  if (loading) return <div>Loading project data...</div>;

  return (
    <div className="executive-projects-container">
      <ExecutiveSidebar />
      <div className="projects-content">
        <h2>Executive Projects Management</h2>

        {/* Add New Project Section */}
        <div className="add-project-form">
          <h3>Add New Project</h3>
          <input type="text" name="name" placeholder="Project Name" value={newProject.name} onChange={handleInputChange} />
          <textarea name="description" placeholder="Project Description" value={newProject.description} onChange={handleInputChange} />
          <select name="clientId" value={newProject.clientId} onChange={handleInputChange}>
            <option value="">Select Client</option>
            {clients.map((client) => (
              <option key={client.id} value={client.id}>{client.name}</option>
            ))}
          </select>
          <select name="salesRep" value={newProject.salesRep} onChange={handleInputChange}>
            <option value="">Select Sales Rep</option>
            {employees.filter(emp => emp.role === 'Sales Rep').map((employee) => (
              <option key={employee.id} value={employee.id}>{employee.name}</option>
            ))}
          </select>
          <select name="developer" value={newProject.developer} onChange={handleInputChange}>
            <option value="">Select Developer</option>
            {employees.filter(emp => emp.role === 'Developer').map((employee) => (
              <option key={employee.id} value={employee.id}>{employee.name}</option>
            ))}
          </select>
          <label>Overall Project Due Date</label>
          <input type="date" name="dueDate" value={newProject.dueDate} onChange={handleInputChange} />
          <textarea name="notes" placeholder="Project Notes" value={newProject.notes} onChange={handleInputChange} />

          <h4>Milestones</h4>
          {milestones.map((milestone, index) => (
            <div key={index} className="milestone-input">
              <input type="text" name="title" placeholder="Milestone Title" value={milestone.title} onChange={(e) => handleMilestoneChange(index, e)} />
              <textarea name="description" placeholder="Milestone Description" value={milestone.description} onChange={(e) => handleMilestoneChange(index, e)} />
              <label>Milestone Deadline</label>
              <input type="date" name="dueDate" value={milestone.dueDate} onChange={(e) => handleMilestoneChange(index, e)} />
              <select name="status" value={milestone.status} onChange={(e) => handleMilestoneChange(index, e)}>
                <option value="Not Started">Not Started</option>
                <option value="Started">Started</option>
                <option value="Completed">Completed</option>
              </select>
              <button onClick={() => removeMilestone(index)} className="remove-milestone-button">Remove Milestone</button>
            </div>
          ))}
          <button onClick={addMilestone}>Add Milestone</button>
          <button onClick={handleAddProject} className="add-button">Add Project</button>
        </div>

        {/* Project List */}
        <table className="project-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Client</th>
              <th>Sales Rep</th>
              <th>Developer</th>
              <th>Due Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.id}>
                <td>{project.name}</td>
                <td>{project.description}</td>
                <td>{clients.find((client) => client.id === project.clientId)?.name || 'N/A'}</td>
                <td>{employees.find((emp) => emp.id === project.salesRep)?.name || 'N/A'}</td>
                <td>{employees.find((emp) => emp.id === project.developer)?.name || 'N/A'}</td>
                <td>{project.dueDate?.toDate ? project.dueDate.toDate().toLocaleDateString() : 'N/A'}</td>
                <td>{project.status}</td>
                <td>
                  <button onClick={() => handleEditClick(project)} className="edit-button">Edit</button>
                  <button onClick={() => handleDelete(project.id)} className="delete-button">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Edit Project Form */}
        {editProject && (
          <div className="edit-form">
            <h3>Edit Project</h3>
            <input type="text" name="name" placeholder="Project Name" value={updatedData.name} onChange={handleUpdateChange} />
            <textarea name="description" placeholder="Project Description" value={updatedData.description} onChange={handleUpdateChange} />
            <select name="clientId" value={updatedData.clientId} onChange={handleUpdateChange}>
              <option value="">Select Client</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
            <select name="salesRep" value={updatedData.salesRep} onChange={handleUpdateChange}>
              <option value="">Select Sales Rep</option>
              {employees.filter(emp => emp.role === 'Sales Rep').map((employee) => (
                <option key={employee.id} value={employee.id}>{employee.name}</option>
              ))}
            </select>
            <select name="developer" value={updatedData.developer} onChange={handleUpdateChange}>
              <option value="">Select Developer</option>
              {employees.filter(emp => emp.role === 'Developer').map((employee) => (
                <option key={employee.id} value={employee.id}>{employee.name}</option>
              ))}
            </select>
            <label>Overall Project Due Date</label>
            <input type="date" name="dueDate" value={updatedData.dueDate} onChange={handleUpdateChange} />
            <textarea name="notes" placeholder="Project Notes" value={updatedData.notes} onChange={handleUpdateChange} />
            {milestones.map((milestone, index) => (
              <div key={index} className="milestone-input">
                <input type="text" name="title" placeholder="Milestone Title" value={milestone.title} onChange={(e) => handleMilestoneChange(index, e)} />
                <textarea name="description" placeholder="Milestone Description" value={milestone.description} onChange={(e) => handleMilestoneChange(index, e)} />
                <label>Milestone Deadline</label>
                <input type="date" name="dueDate" value={milestone.dueDate} onChange={(e) => handleMilestoneChange(index, e)} />
                <select name="status" value={milestone.status} onChange={(e) => handleMilestoneChange(index, e)}>
                  <option value="Not Started">Not Started</option>
                  <option value="Started">Started</option>
                  <option value="Completed">Completed</option>
                </select>
                <button onClick={() => removeMilestone(index)} className="remove-milestone-button">Remove Milestone</button>
              </div>
            ))}
            <button onClick={handleUpdateSubmit} className="save-button">Save</button>
            <button onClick={() => setEditProject(null)} className="cancel-button">Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExecutiveProjects;
