import React, { useState } from 'react';
import './SDVOSBCompliance.css';
import HeaderBar from './HeaderBar';
import { db } from '../../firebase/firebaseConfig'; // Updated path to Firebase config
import { collection, addDoc, Timestamp } from 'firebase/firestore';


function SDVOSBCompliance() {
  const [interestSubmitted, setInterestSubmitted] = useState(false);
  const [businessType, setBusinessType] = useState('SDVOSB');
  const [formData, setFormData] = useState({
    businessName: '',
    contactName: '',
    email: '',
    phone: '',
    dunsOrSamNumber: '',
    businessDescription: '',
    expertiseOrResources: '',
    interestReason: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInterestSubmit = async (event) => {
    event.preventDefault();
    
    // Prepare data based on business type
    const submissionData = {
      businessType,
      businessName: formData.businessName,
      contactName: formData.contactName,
      email: formData.email,
      phone: formData.phone || null,
      dunsOrSamNumber: businessType === 'SDVOSB' ? formData.dunsOrSamNumber : null,
      businessDescription: businessType === 'SDVOSB' ? formData.businessDescription : null,
      expertiseOrResources: businessType === 'Large Firm' ? formData.expertiseOrResources : null,
      interestReason: businessType === 'Large Firm' ? formData.interestReason : null,
      verified: false,
      verificationStatus: 'Pending',
      dateSubmitted: Timestamp.now()
    };

    try {
      // Add document to Firestore
      await addDoc(collection(db, 'SDVOSBComplianceEarlyEntry'), submissionData);
      setInterestSubmitted(true); // Show thank you message after submission
    } catch (error) {
      console.error("Error submitting interest form: ", error);
    }
  };

  return (
    <div className="sdvosb-compliance-page">
      <HeaderBar />
      <div className="content-container">
        <h1>SDVOSB Compliance Consulting</h1>
        <p className="coming-soon-text">
          Coming Soon: A compliance-driven platform empowering SDVOSBs through strategic partnerships with large firms, ensuring federal compliance and maximized contract opportunities.
        </p>

        <div className="mission-section">
          <h2>Our Mission</h2>
          <p>
            Our mission is to bridge the gap between Service-Disabled Veteran-Owned Small Businesses (SDVOSBs) and large firms, providing a pathway for SDVOSBs to thrive in the federal contracting space. We focus on compliance, ensuring both parties adhere to federal requirements, so SDVOSBs can leverage resources from large firms without risking non-compliance.
          </p>
        </div>

        <div className="how-we-help-section">
          <h2>How We Help</h2>
          <ul>
            <li><strong>Compliance Monitoring:</strong> We provide ongoing compliance tracking to ensure SDVOSBs meet the contract requirements for participation and work completion.</li>
            <li><strong>Resource Alignment:</strong> Partner SDVOSBs with large firms to access necessary resources and expertise, enhancing their ability to bid for and fulfill federal contracts.</li>
            <li><strong>Bid Support:</strong> With our platform, SDVOSBs can focus on scaling their impact, while we ensure compliance at every stage, from bid submission to project completion.</li>
          </ul>
        </div>

        <div className="early-access-section">
          <h2>Early Access Registration</h2>
          <p>
            Choose your business type below and register for early access to receive a free month of compliance consulting at launch.
          </p>
          <div className="business-type-selector">
            <button onClick={() => setBusinessType('SDVOSB')} className={`type-btn ${businessType === 'SDVOSB' ? 'active' : ''}`}>SDVOSB</button>
            <button onClick={() => setBusinessType('Large Firm')} className={`type-btn ${businessType === 'Large Firm' ? 'active' : ''}`}>Large Firm</button>
          </div>

          {!interestSubmitted ? (
            <form onSubmit={handleInterestSubmit} className="interest-form">
              <input type="text" name="businessName" placeholder="Business Name" value={formData.businessName} onChange={handleInputChange} required />
              <input type="text" name="contactName" placeholder="Contact Person Name" value={formData.contactName} onChange={handleInputChange} required />
              <input type="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleInputChange} required />
              <input type="tel" name="phone" placeholder="Phone Number (optional)" value={formData.phone} onChange={handleInputChange} />

              {businessType === 'SDVOSB' && (
                <>
                  <input type="text" name="dunsOrSamNumber" placeholder="DUNS or SAM Number (optional)" value={formData.dunsOrSamNumber} onChange={handleInputChange} />
                  <textarea name="businessDescription" placeholder="Brief Business Description" rows="3" value={formData.businessDescription} onChange={handleInputChange}></textarea>
                </>
              )}

              {businessType === 'Large Firm' && (
                <>
                  <textarea name="expertiseOrResources" placeholder="Areas of Expertise/Resources Available" rows="3" value={formData.expertiseOrResources} onChange={handleInputChange}></textarea>
                  <textarea name="interestReason" placeholder="Reason for Interest in Supporting SDVOSBs" rows="3" value={formData.interestReason} onChange={handleInputChange}></textarea>
                </>
              )}

              <button type="submit" className="submit-btn">Submit Interest</button>
            </form>
          ) : (
            <p className="thank-you-text">Thank you! We'll reach out with early access details.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SDVOSBCompliance;
