import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ClientSidebar.css';

function ClientSidebar() {
  const navigate = useNavigate();

  return (
    <div className="client-sidebar">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/aigencyoneapp.appspot.com/o/Logos%2FCompliance%20Company.png?alt=media&token=49f22cce-9396-4985-86e1-5e5df82fe1a8"
        alt="Company Logo"
        className="client-sidebar-logo"
      />
      <h2>Client Dashboard</h2>
      <ul>
        <li onClick={() => navigate('/client-dashboard')}>Dashboard</li>
        <li onClick={() => navigate('/client-project-overview')}>Projects</li>
        <li onClick={() => navigate('/client-payment-overview')}>Payments</li>
        <li onClick={() => navigate('/client-settings')}>Settings</li>
      </ul>
    </div>
  );
}

export default ClientSidebar;
