import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase/firebaseConfig';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import './ExecutiveClientAccounts.css';
import ExecutiveSidebar from './ExecutiveSidebar';

function ExecutiveClientAccounts() {
  const [clients, setClients] = useState([]);
  const [editClient, setEditClient] = useState(null);
  const [updatedData, setUpdatedData] = useState({
    name: '',
    email: '',
    phone: '',
    billingAddress: '',
    assignedProjects: [],
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClients = async () => {
      const clientSnapshot = await getDocs(collection(db, 'Clients'));
      const clientList = clientSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(clientList);
      setLoading(false);
    };

    fetchClients();
  }, []);

  const handleEditClick = (client) => {
    setEditClient(client);
    setUpdatedData({
      name: client.name,
      email: client.email,
      phone: client.phone,
      billingAddress: client.billingAddress,
      assignedProjects: client.assignedProjects,
    });
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdateSubmit = async () => {
    if (editClient) {
      const clientRef = doc(db, 'Clients', editClient.id);
      await updateDoc(clientRef, updatedData);
      setClients((prevClients) =>
        prevClients.map((client) => (client.id === editClient.id ? { ...client, ...updatedData } : client))
      );
      setEditClient(null);
      setUpdatedData({ name: '', email: '', phone: '', billingAddress: '', assignedProjects: [] });
    }
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'Clients', id));
    setClients((prevClients) => prevClients.filter((client) => client.id !== id));
  };

  const filteredClients = clients.filter(
    (client) =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <div>Loading client data...</div>;

  return (
    <div className="executive-client-accounts-container">
      <ExecutiveSidebar />
      <div className="client-accounts-content">
        <h2>Executive Client Accounts</h2>
        
        <div className="filter-section">
          <input
            type="text"
            className="search-input"
            placeholder="Search by name or email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <table className="client-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Billing Address</th>
              <th>Assigned Projects</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredClients.map((client) => (
              <tr key={client.id}>
                <td>{client.name}</td>
                <td>{client.email}</td>
                <td>{client.phone}</td>
                <td>{client.billingAddress}</td>
                <td>{client.assignedProjects.join(', ')}</td>
                <td>
                  <button onClick={() => handleEditClick(client)} className="edit-button">Edit</button>
                  <button onClick={() => handleDelete(client.id)} className="delete-button">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {editClient && (
          <div className="edit-form">
            <h3>Edit Client</h3>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={updatedData.name}
              onChange={handleUpdateChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={updatedData.email}
              onChange={handleUpdateChange}
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={updatedData.phone}
              onChange={handleUpdateChange}
            />
            <input
              type="text"
              name="billingAddress"
              placeholder="Billing Address"
              value={updatedData.billingAddress}
              onChange={handleUpdateChange}
            />
            <button onClick={handleUpdateSubmit} className="save-button">Save</button>
            <button onClick={() => setEditClient(null)} className="cancel-button">Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExecutiveClientAccounts;
