import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SalesSidebar.css';

function SalesSidebar() {
  const navigate = useNavigate();

  return (
    <div className="sales-sidebar">
      <h2 className="sidebar-title">Sales Dashboard</h2>
      <ul className="sidebar-menu">
        <li onClick={() => navigate('/sales-dashboard')}>Dashboard Overview</li>
        <li onClick={() => navigate('/sales-projects-overview')}>Projects Overview</li>
        <li onClick={() => navigate('/sales-leads')}>Leads</li>
        <li onClick={() => navigate('/sales-clients')}>Clients</li>
        <li onClick={() => navigate('/sales-analytics')}>Analytics</li>
        <li onClick={() => navigate('/sales-settings')}>Settings</li>
      </ul>
    </div>
  );
}

export default SalesSidebar;
