import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../../firebase/firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';
import './ExecutiveDashboard.css';
import ExecutiveSidebar from './ExecutiveSidebar';

function ExecutiveDashboard() {
  const [employee, setEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const [departmentCounts, setDepartmentCounts] = useState({});
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Set persistence to local to avoid logout after refresh
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Listen for auth state changes
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            console.log("User is authenticated:", user.uid);
            try {
              const employeeDoc = await getDoc(doc(db, 'Employees', user.uid));
              if (employeeDoc.exists()) {
                setEmployee(employeeDoc.data());
              } else {
                console.error("Employee data not found");
                navigate('/team-login');
              }
            } catch (error) {
              console.error("Error fetching employee data:", error);
              navigate('/team-login');
            }
          } else {
            console.warn("No user is authenticated");
            navigate('/team-login');
          }
          setLoading(false);
        });
        return unsubscribe;
      })
      .catch((error) => {
        console.error("Error setting persistence:", error);
      });
  }, [navigate]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const employeeSnapshot = await getDocs(collection(db, 'Employees'));
      const employeeList = employeeSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeeList);

      const departmentCounts = employeeList.reduce((acc, employee) => {
        const dept = employee.department || 'Unknown';
        acc[dept] = (acc[dept] || 0) + 1;
        return acc;
      }, {});
      setDepartmentCounts(departmentCounts);
    };

    const fetchTotalProjects = async () => {
      const projectsSnapshot = await getDocs(collection(db, 'Projects'));
      setTotalProjects(projectsSnapshot.size);
    };

    const fetchTotalRevenue = async () => {
      const paymentsSnapshot = await getDocs(collection(db, 'Payments'));
      const total = paymentsSnapshot.docs.reduce((acc, doc) => acc + (doc.data().amount || 0), 0);
      setTotalRevenue(total);
    };

    fetchEmployees();
    fetchTotalProjects();
    fetchTotalRevenue();
  }, []);

  const goToTeamSignUp = () => {
    navigate('/team-sign-up');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!employee) {
    return null;
  }

  return (
    <div className="executive-dashboard-container">
      <div className="dashboard-content">
      <ExecutiveSidebar />
        <h1>Welcome, {employee.name}!</h1>
        <p>Executive Dashboard - Manage your team, projects, and finances.</p>

        {/* Overview Section */}
        <section className="dashboard-overview">
          <div className="overview-card">
            <h3>Total Projects</h3>
            <p>{totalProjects} Ongoing Projects</p>
          </div>
          <div className="overview-card">
            <h3>Total Employees</h3>
            <p>{employees.length} Employees</p>
          </div>
          <div className="overview-card">
            <h3>Total Revenue</h3>
            <p>${totalRevenue.toLocaleString()}</p>
          </div>
        </section>

        {/* Department Counts */}
        <section className="department-counts">
          <h2>Employees by Department</h2>
          <div className="department-cards">
            {Object.entries(departmentCounts).map(([dept, count]) => (
              <div className="department-card" key={dept}>
                <h3>{dept}</h3>
                <p>{count} Employees</p>
              </div>
            ))}
          </div>
        </section>

        {/* Navigate to Team Sign-Up */}
        <section className="add-employee-section">
          <h2>Add New Employee</h2>
          <button onClick={goToTeamSignUp} className="dashboard-button">Go to Team Sign-Up</button>
        </section>
      </div>
    </div>
  );
}

export default ExecutiveDashboard;
