import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import "./ExecutiveFinancialReports.css";
import Sidebar from "../executive/ExecutiveSidebar"; // Assuming you have a Sidebar component in your executive folder
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const ExecutiveFinancialReports = () => {
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [averagePayment, setAveragePayment] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [monthlyRevenue, setMonthlyRevenue] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const db = getFirestore();

  useEffect(() => {
    const fetchPayments = async () => {
      const paymentsSnapshot = await getDocs(collection(db, "Payments"));
      let revenue = 0;
      let paymentCount = 0;
      const monthlyData = {};
      const transactionList = [];

      paymentsSnapshot.forEach(doc => {
        const payment = doc.data();
        const amount = payment.amount;
        const date = new Date(payment.date);

        revenue += amount;
        paymentCount += 1;
        transactionList.push({ date: date.toLocaleDateString(), amount });

        const month = `${date.getMonth() + 1}/${date.getFullYear()}`;
        if (!monthlyData[month]) monthlyData[month] = 0;
        monthlyData[month] += amount;
      });

      setTotalRevenue(revenue);
      setAveragePayment(revenue / paymentCount);
      setTotalTransactions(paymentCount);
      setTransactions(transactionList);
      setMonthlyRevenue(Object.entries(monthlyData).map(([month, amount]) => ({ month, amount })));
    };

    fetchPayments();
  }, [db]);

  return (
    <div className="executive-dashboard">
      <Sidebar />
      <div className="financial-reports-content">
        <h1>Financial Reports</h1>

        {/* Overview Financial Metrics */}
        <div className="financial-metrics">
          <div className="metric">
            <h3>Total Revenue</h3>
            <p>${totalRevenue.toLocaleString()}</p>
          </div>
          <div className="metric">
            <h3>Average Payment</h3>
            <p>${averagePayment.toFixed(2)}</p>
          </div>
          <div className="metric">
            <h3>Total Transactions</h3>
            <p>{totalTransactions}</p>
          </div>
        </div>

        {/* Monthly Revenue Trend */}
        <div className="revenue-trend">
          <h2>Monthly Revenue Trend</h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={monthlyRevenue}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="amount" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </div>

        {/* Transaction List */}
        <div className="transaction-list">
          <h2>Transaction History</h2>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.date}</td>
                  <td>${transaction.amount.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ExecutiveFinancialReports;
