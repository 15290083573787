import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import SalesDashboard from './components/teamside/sales/SalesDashboard';
import ClaimedLeads from './components/teamside/marketing/ClaimedLeads';
import LandingPage from './components/landingpage/LandingPage';
import ClientLogin from './components/login/client/ClientLogin';
import TeamLogin from './components/login/team/TeamLogin';
import ClientDashboard from './components/clientside/ClientDashboard';
import ExecutiveSidebar from './components/teamside/executive/ExecutiveSidebar';
import CareerOptions from './components/landingpage/CareerOptions';
import AboutUs from './components/landingpage/AboutUs';
import ContactUs from './components/landingpage/ContactUs';
import QuoteTool from './components/quoting/QuoteTool';
import ConsultationRequest from './components/landingpage/ConsultationRequest';
import ClientSignUp from './components/login/client/ClientSignUp';
import ClientForgotPassword from './components/login/client/ClientForgotPassword';
import ExecutiveDashboard from './components/teamside/executive/ExecutiveDashboard';
import TeamSignUp from './components/login/team/TeamSignUp';
import ExecutiveTeamManagement from './components/teamside/executive/ExecutiveTeamManagement';
import ExecutiveClientAccounts from './components/teamside/executive/ExecutiveClientAccounts';
import ExecutiveProjects from './components/teamside/executive/ExecutiveProjects';
import ExecutiveFinancialReports from './components/teamside/executive/ExecutiveFinancialReports';
import ExecutiveAnalytics from './components/teamside/executive/ExecutiveAnalytics';
import ExecutiveSettings from './components/teamside/executive/ExecutiveSettings';
import SalesProjectsOverview from './components/teamside/sales/SalesProjectsOverview.';
import DevelopmentPartners from './components/landingpage/DevelopmentPartners';
import ClientProjectOverview from './components/clientside/ClientProjectOverview';
import ClientPaymentOverview from './components/clientside/ClientPaymentOverview';
import ClientSettings from './components/clientside/ClientSettings';
import BusinessSystems from './components/landingpage/BusinessSystems';
import SDVOSBCompliance from './components/landingpage/SDVOSBCompliance';
import OwnerPopup from './components/landingpage/OwnerPage';
import Products from './components/landingpage/Products';

function App() {
  const location = useLocation();
  const [isOwnerPopupOpen, setIsOwnerPopupOpen] = useState(false);

  // Define paths where the ExecutiveSidebar should be visible
  const executivePaths = [
    '/executive-dashboard',
    '/executive-team-management',
    '/executive-projects',
    '/financial-reports',
    '/executive-client-accounts',
    '/executive-analytics',
    '/executive-settings',
  ];

  // Define paths where the PMSidebar should be visible
  const pmPaths = [
    '/project-management-dashboard',
    '/task-management',
    '/client-communication',
    '/billing-invoices',
    '/dialer',
  ];

  const isExecutiveRoute = executivePaths.includes(location.pathname);
  const isPMRoute = pmPaths.includes(location.pathname);

  // Open and close pop-up functions
  const openOwnerPopup = () => setIsOwnerPopupOpen(true);
  const closeOwnerPopup = () => setIsOwnerPopupOpen(false);

  return (
    <div className="app-container">
      {isExecutiveRoute && <ExecutiveSidebar />}
      
      <div className={isExecutiveRoute || isPMRoute ? "main-content" : ""}>
        <Routes>
          {/* Landing Page Route */}
          <Route path="/" element={<LandingPage openOwnerPopup={openOwnerPopup} />} />

          {/* Client and Team Login Routes */}
          <Route path="/client-login" element={<ClientLogin />} />
          <Route path="/team-login" element={<TeamLogin />} />

          {/* Client Dashboard Route */}
          <Route path="/client-dashboard" element={<ClientDashboard />} />

          {/* Career Options Route */}
          <Route path="/career-options" element={<CareerOptions />} />

          {/* About Us Route */}
          <Route path="/about-us" element={<AboutUs />} />

          {/* Contact Us Route */}
          <Route path="/contact-us" element={<ContactUs />} />

          {/* Quote Tool Route */}
          <Route path="/quote-tool" element={<QuoteTool />} />

          {/* Consultation Request Modal */}
          <Route path="/request-consultation" element={<ConsultationRequest />} />

          <Route path="/sign-up" element={<ClientSignUp />} />

          <Route path="/forgot-password" element={<ClientForgotPassword />} />

          {/* Executive Dashboard Routes */}
          <Route path="/executive-dashboard" element={<ExecutiveDashboard />} />
          <Route path="/executive-team-management" element={<ExecutiveTeamManagement />} />
          <Route path="/executive-client-accounts" element={<ExecutiveClientAccounts />} />
          <Route path="/executive-projects" element={<ExecutiveProjects />} />
          <Route path="/executive-financial-reports" element={<ExecutiveFinancialReports />} />
          <Route path="/executive-analytics" element={<ExecutiveAnalytics />} />
          <Route path="/executive-settings" element={<ExecutiveSettings />} />

          {/* Team Dashboard and Components */}
          <Route path="/sales-dashboard" element={<SalesDashboard />} />
          <Route path="/claimed-leads" element={<ClaimedLeads />} />
      
          <Route path='/team-sign-up' element={<TeamSignUp />} />

          <Route path="/sales-projects-overview" element={<SalesProjectsOverview />} />

          <Route path="/development-partners" element={<DevelopmentPartners />} />

          <Route path="/client-project-overview" element={<ClientProjectOverview />} />

          <Route path="/client-payment-overview" element={<ClientPaymentOverview />} />

          <Route path="/client-settings" element={<ClientSettings />} />

          <Route path="/business-systems" element={<BusinessSystems />} />

          <Route path="/sdvosb-compliance" element={<SDVOSBCompliance />} />

          <Route path="/owner-popup" element={<OwnerPopup isOpen={isOwnerPopupOpen} closePopup={closeOwnerPopup} />} />

          <Route path="/products" element={<Products />} />
        </Routes>

      </div>
    </div>
  );
}

function WrappedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default WrappedApp;
