import React, { useState } from 'react';
import { db } from '../../firebase/firebaseConfig'; // Adjust the path as needed
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import './ConsultationRequest.css'; // Import the CSS for the modal

function ConsultationRequest({ onClose }) {
  const [formData, setFormData] = useState({
    budget: '',
    companyName: '',
    companySize: '',
    companyWebsite: '',
    decisionRole: '',
    fullName: '',
    industry: '',
    location: '',
    phone: '',
    projectDescription: '',
    timeline: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'ConsultationRequests'), {
        ...formData,
        submittedAt: serverTimestamp(),
      });
      setSuccess('Your consultation request has been submitted successfully.');
      setFormData({
        budget: '',
        companyName: '',
        companySize: '',
        companyWebsite: '',
        decisionRole: '',
        fullName: '',
        industry: '',
        location: '',
        phone: '',
        projectDescription: '',
        timeline: '',
      });
      setError('');
    } catch (error) {
      console.error('Error submitting consultation request:', error);
      setError('There was an issue submitting your request. Please try again.');
      setSuccess('');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Request a Consultation</h2>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        <form onSubmit={handleSubmit}>
          <input type="text" name="fullName" placeholder="Your Full Name" value={formData.fullName} onChange={handleChange} required />
          <input type="text" name="companyName" placeholder="Company Name" value={formData.companyName} onChange={handleChange} required />
          <input type="text" name="companyWebsite" placeholder="Business Website" value={formData.companyWebsite} onChange={handleChange} />
          <input type="text" name="industry" placeholder="Industry" value={formData.industry} onChange={handleChange} required />
          <select name="companySize" value={formData.companySize} onChange={handleChange} required>
            <option value="">Company Size</option>
            <option value="1-10">1-10 Employees</option>
            <option value="11-50">11-50 Employees</option>
            <option value="51-200">51-200 Employees</option>
            <option value="201-500">201-500 Employees</option>
            <option value="500+">500+ Employees</option>
          </select>
          <input type="text" name="location" placeholder="Business Location (Optional)" value={formData.location} onChange={handleChange} />
          <input type="tel" name="phone" placeholder="Phone Number" value={formData.phone} onChange={handleChange} required />
          <textarea name="projectDescription" placeholder="Describe Your Project or Needs" value={formData.projectDescription} onChange={handleChange} required></textarea>
          <select name="budget" value={formData.budget} onChange={handleChange} required>
            <option value="">Estimated Budget</option>
            <option value="<$5,000">Less than $5,000</option>
            <option value="$5,000-$10,000">$5,000-$10,000</option>
            <option value="$10,000-$50,000">$10,000-$50,000</option>
            <option value=">$50,000">More than $50,000</option>
          </select>
          <select name="timeline" value={formData.timeline} onChange={handleChange} required>
            <option value="">Expected Timeline</option>
            <option value="ASAP">ASAP</option>
            <option value="1-3 months">1-3 months</option>
            <option value="3-6 months">3-6 months</option>
            <option value="6+ months">6+ months</option>
          </select>
          <input type="text" name="decisionRole" placeholder="Your Role (CEO, CTO, etc.)" value={formData.decisionRole} onChange={handleChange} required />
          <button type="submit" className="btn primary-btn">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default ConsultationRequest;
