import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase/firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import ClientSidebar from './ClientSidebar';
import StripePaymentModal from '../payments/StripePaymentModal';
import styles from './ClientProjectOverview.module.css';

function ClientProjectOverview() {
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const [activeProjects, setActiveProjects] = useState(0);
  const [completedProjects, setCompletedProjects] = useState(0);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      const user = auth.currentUser;
      if (user) {
        const projectsQuery = query(
          collection(db, 'Projects'),
          where('clientId', '==', user.uid)
        );

        const projectSnapshots = await getDocs(projectsQuery);
        const projectList = projectSnapshots.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setProjects(projectList);
        setTotalProjects(projectList.length);
        setActiveProjects(projectList.filter(project => project.status === 'Active').length);
        setCompletedProjects(projectList.filter(project => project.status === 'Completed').length);
      } else {
        navigate('/login');
      }
    };

    fetchProjects();
  }, [navigate]);

  const calculateProgress = (project) => {
    const completedMilestones = project.milestones?.filter(milestone => milestone.status === 'Completed').length || 0;
    const totalMilestones = project.milestones?.length || 1;
    return (completedMilestones / totalMilestones) * 100;
  };

  const handleViewDetails = (projectId) => {
    navigate(`/project-details/${projectId}`);
  };

  const handlePayNow = (project) => {
    setSelectedProject(project);
    setShowPaymentModal(true);
  };

  return (
    <div className={styles.clientProjectOverview}>
      <ClientSidebar />
      <div className={styles.clientProjectContent}>
        <h1>Project Dashboard</h1>

        <section className={styles.projectOverviewSection}>
          <div className={styles.overviewCard}>
            <h3>Total Projects</h3>
            <p>{totalProjects}</p>
          </div>
          <div className={styles.overviewCard}>
            <h3>Active Projects</h3>
            <p>{activeProjects}</p>
          </div>
          <div className={styles.overviewCard}>
            <h3>Completed Projects</h3>
            <p>{completedProjects}</p>
          </div>
        </section>

        <section className={styles.projectListSection}>
          <h2>Your Projects</h2>
          <div className={styles.projectList}>
            {projects.length > 0 ? (
              projects.map((project) => (
                <div key={project.id} className={styles.projectItem}>
                  <h3 className={styles.projectTitle}>{project.name}</h3>
                  <p>Status: {project.status}</p>
                  <div className={styles.progressBar}>
                    <div
                      className={styles.progress}
                      style={{ width: `${calculateProgress(project)}%` }}
                    ></div>
                  </div>
                  <p>Progress: {calculateProgress(project).toFixed(2)}%</p>
                  <div className={styles.buttons}>
                    <button
                      className={styles.viewDetailsButton}
                      onClick={() => handleViewDetails(project.id)}
                    >
                      View Details
                    </button>
                    <button
                      className={styles.payNowButton}
                      onClick={() => handlePayNow(project)}
                    >
                      Pay Now
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No projects found.</p>
            )}
          </div>
        </section>
      </div>

      {/* Stripe Payment Modal */}
      {showPaymentModal && selectedProject && (
        <StripePaymentModal
          projects={[selectedProject]}
          onClose={() => setShowPaymentModal(false)}
        />
      )}
    </div>
  );
}

export default ClientProjectOverview;
