import React, { useState } from 'react';
import { db } from '../../firebase/firebaseConfig'; // Adjust this import based on your file structure
import { collection, addDoc } from 'firebase/firestore';
import './ContactUs.css';
import HeaderBar from './HeaderBar';

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Add the form data to Firestore
      await addDoc(collection(db, 'ContactMessages'), {
        name: formData.name,
        email: formData.email,
        message: formData.message,
        timestamp: new Date(), // Add a timestamp for when the message was sent
      });

      alert('Your message has been sent. We will get back to you shortly!');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error sending message: ', error);
      alert('There was an error sending your message. Please try again later.');
    }
  };

  return (
    <div className="contact-page">
      {/* Video Background */}
      <div className="contact-video">
        <video autoPlay loop muted playsInline className="background-video">
          <source src="https://storage.googleapis.com/logohostbucket/TechBackground1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Include Header Bar */}
      <HeaderBar />

      <div className="contact-us-container">
        <h1>Contact Us</h1>
        <p>We'd love to hear from you. Please fill out the form below, and we will get back to you as soon as possible.</p>

        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Your Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Your Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Write your message here"
              rows="6"
              required
            ></textarea>
          </div>

          <button type="submit" className="submit-btn">Send Message</button>
        </form>

        <div className="contact-info">
          <h2>Our Contact Information</h2>
          <p>Email: marcus@aigencyone.com</p>
          <p>Phone: 971-977-3938</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
