import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth, db } from '../../../firebase/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import './TeamLogin.css';
import HeaderBar from '../../landingpage/HeaderBar';

function TeamLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message
    setMessage(''); // Reset success message

    try {
      // Authenticate the user with Firebase Auth
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // Check if the authenticated user is in the Employees collection
      const employeeDoc = await getDoc(doc(db, 'Employees', userId));
      if (employeeDoc.exists()) {
        const employeeData = employeeDoc.data();
        const { department } = employeeData;

        // Route based on the employee's department
        switch (department) {
          case 'Executive':
            navigate('/executive-dashboard');
            break;
          case 'Sales':
            navigate('/sales-dashboard');
            break;
          case 'Customer Service':
            navigate('/customer-service-dashboard');
            break;
          case 'Developer':
            navigate('/developer-dashboard');
            break;
          default:
            setError('Access denied: Unrecognized department.');
        }
      } else {
        setError('Access denied: You do not have permission to access this area.');
      }
    } catch (err) {
      setError('Invalid email or password');
      console.error('Login error:', err);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email to reset your password');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent. Please check your inbox.');
      setError('');
    } catch (err) {
      setError('Failed to send password reset email. Please try again.');
      console.error('Password reset error:', err);
    }
  };

  return (
    <div className="login-page">
      <div className="login-video">
        <video autoPlay loop muted playsInline className="background-video">
          <source src="https://storage.googleapis.com/logohostbucket/TechBackground1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <HeaderBar />

      <div className="login-box">
        <h1>Team Login</h1>
        {error && <p className="error-message">{error}</p>}
        {message && <p className="success-message">{message}</p>}
        <form onSubmit={handleLogin} className="login-form">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="login-input"
          />
          <button type="submit" className="login-button">Login</button>
        </form>

        {/* Forgot Password Link */}
        <button onClick={handleForgotPassword} className="forgot-password-link">
          Forgot Password?
        </button>
      </div>
    </div>
  );
}

export default TeamLogin;
