import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase/firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import ClientSidebar from './ClientSidebar';
import StripePaymentModal from '../payments/StripePaymentModal';
import styles from './ClientPaymentOverview.module.css';

function ClientPaymentOverview() {
  const [payments, setPayments] = useState([]);
  const [totalPayments, setTotalPayments] = useState(0);
  const [outstandingPayments, setOutstandingPayments] = useState(0);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [projects, setProjects] = useState([]); // List of projects with milestones
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        // Fetch payments
        const paymentsQuery = query(
          collection(db, 'Payments'),
          where('clientId', '==', user.uid)
        );
        const paymentSnapshots = await getDocs(paymentsQuery);
        const paymentList = paymentSnapshots.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPayments(paymentList);

        // Calculate total and outstanding payments
        const total = paymentList.reduce((acc, payment) => acc + (payment.amount || 0), 0);
        const outstanding = paymentList
          .filter(payment => !payment.isPaid)
          .reduce((acc, payment) => acc + (payment.amount || 0), 0);
        setTotalPayments(total);
        setOutstandingPayments(outstanding);

        // Fetch projects with milestones
        const projectsQuery = query(
          collection(db, 'Projects'),
          where('clientId', '==', user.uid)
        );
        const projectSnapshots = await getDocs(projectsQuery);
        const projectList = projectSnapshots.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProjects(projectList);
      } else {
        navigate('/login');
      }
    };

    fetchData();
  }, [navigate]);

  // Handler for opening the payment modal
  const handleMakePayment = (amount) => {
    setPaymentAmount(amount);
    setShowPaymentModal(true);
  };

  return (
    <div className={styles.clientPaymentOverview}>
      <ClientSidebar />
      <div className={styles.clientPaymentContent}>
        <h1>Payment Dashboard</h1>

        {/* Make a Payment Button */}
        <button className={styles.makePaymentButton} onClick={() => handleMakePayment(outstandingPayments)}>
          Make a Payment
        </button>

        {/* Overview Section */}
        <section className={styles.paymentOverviewSection}>
          <div className={styles.overviewCard}>
            <h3>Total Payments</h3>
            <p>${totalPayments.toFixed(2)}</p>
          </div>
          <div className={styles.overviewCard}>
            <h3>Outstanding Balance</h3>
            <p>${outstandingPayments.toFixed(2)}</p>
          </div>
        </section>

        {/* Payment List Section */}
        <section className={styles.paymentListSection}>
          <h2>Your Payments</h2>
          <div className={styles.paymentList}>
            {payments.length > 0 ? (
              payments.map((payment) => (
                <div key={payment.id} className={styles.paymentItem}>
                  <h3 className={styles.paymentTitle}>Invoice #{payment.invoiceNumber || payment.id}</h3>
                  <p>Amount: ${payment.amount.toFixed(2)}</p>
                  <p>Status: {payment.isPaid ? "Paid" : "Unpaid"}</p>
                  <p>Due Date: {payment.dueDate ? new Date(payment.dueDate.toDate()).toLocaleDateString() : "N/A"}</p>
                  {!payment.isPaid && (
                    <button className={styles.payNowButton} onClick={() => handleMakePayment(payment.amount)}>
                      Pay Now
                    </button>
                  )}
                </div>
              ))
            ) : (
              <p>No payments found.</p>
            )}
          </div>
        </section>
      </div>

      {/* Stripe Payment Modal */}
      {showPaymentModal && (
        <StripePaymentModal
          projects={projects} // Pass the list of projects with milestones
          amount={paymentAmount}
          onClose={() => setShowPaymentModal(false)} // Close the modal
        />
      )}
    </div>
  );
}

export default ClientPaymentOverview;
