import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './HeaderBar.css';

function HeaderBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleDropdown = (dropdown) => {
    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  return (
    <header className="header-bar">
      {/* Mobile Menu Toggle */}
      <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
        <span className={`burger ${isMobileMenuOpen ? 'open' : ''}`}></span>
        <span className={`burger ${isMobileMenuOpen ? 'open' : ''}`}></span>
        <span className={`burger ${isMobileMenuOpen ? 'open' : ''}`}></span>
      </div>

      {/* Logo */}
      <Link to="/">
        <img
          src="https://storage.googleapis.com/logohostbucket/aione-1-black-transp.png"
          alt="AigencyOne Logo"
          className="header-logo"
        />
      </Link>

      {/* Call Now Button */}
      <Link to="/contact-us" className="btn call-now-btn">Call Now</Link>

      {/* Right-side Navigation */}
      <div className={`nav-right ${isMobileMenuOpen ? 'open' : ''}`}>
        <nav>
          {/* Products Link */}
          <Link to="/products" className="nav-item">Products</Link>

          {/* Services Dropdown */}
          <div className="nav-item" onMouseEnter={() => toggleDropdown('services')} onMouseLeave={() => setActiveDropdown(null)}>
            <Link to="#" className="dropdown-trigger">Services</Link>
            {activeDropdown === 'services' && (
              <div className="dropdown">
                <Link to="/business-systems">Business Systems</Link>
                <Link to="/sdvosb-compliance">SDVOSB Compliance</Link>
              </div>
            )}
          </div>

          {/* Company Dropdown */}
          <div className="nav-item" onMouseEnter={() => toggleDropdown('company')} onMouseLeave={() => setActiveDropdown(null)}>
            <Link to="#" className="dropdown-trigger">Company</Link>
            {activeDropdown === 'company' && (
              <div className="dropdown">
                <Link to="/development-partners">Development Team</Link>
                <Link to="/career-options">Career Options</Link>
                <Link to="/about-us">About Us</Link>
                <Link to="/owner-popup">Meet the Owner</Link>
              </div>
            )}
          </div>

          {/* Log In Dropdown */}
          <div className="nav-item" onMouseEnter={() => toggleDropdown('login')} onMouseLeave={() => setActiveDropdown(null)}>
            <Link to="#" className="dropdown-trigger">Log In</Link>
            {activeDropdown === 'login' && (
              <div className="dropdown">
                <Link to="/client-login">Client Login</Link>
                <Link to="/team-login">Team Login</Link>
              </div>
            )}
          </div>

          {/* Contact Us */}
          <Link to="/contact-us">Contact Us</Link>
        </nav>
      </div>
    </header>
  );
}

export default HeaderBar;
