import React from 'react';
import HeaderBar from './HeaderBar'; // Ensure the path to HeaderBar is correct
import './OwnerPage.css';

function OwnerPage() {
  const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/aigencyoneapp.appspot.com/o/Owner%2FMarcus%20Campbell.png?alt=media&token=00ed326a-0f77-4166-bdc2-bc657d865ece';

  return (
    <div className="owner-page">
      <HeaderBar />
      <div className="image-container">
        <img src={imageUrl} alt="Marcus Campbell - Meet the Owner" className="owner-image" />
      </div>
    </div>
  );
}

export default OwnerPage;
