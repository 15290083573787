import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase/firebaseConfig';
import { db } from '../../../firebase/firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import './ClientSignUp.css';
import HeaderBar from '../../landingpage/HeaderBar';

function ClientSignUp() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    verifyPassword: '',
    name: '',
    phone: '',
    companyName: '',
    accountStatus: 'active',
    billingDetails: {
      outstandingBalance: 0,
    },
    notes: '',
    contactPreferences: {
      email: false,
      phone: false,
    },
    socialMedia: {
      linkedIn: '',
      twitter: '',
      facebook: '',
      instagram: '',
      youtube: '',
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Check if passwords match
  const passwordsMatch = formData.password === formData.verifyPassword;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('socialMedia') || name.includes('contactPreferences')) {
      const [category, field] = name.split('.');
      setFormData((prevData) => ({
        ...prevData,
        [category]: {
          ...prevData[category],
          [field]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!passwordsMatch) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const clientId = userCredential.user.uid;

      await setDoc(doc(db, 'Clients', clientId), {
        ...formData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        assignedProjects: [],
      });

      navigate('/client-dashboard');
    } catch (err) {
      setError('Failed to create account. Please try again.');
      console.error(err);
    }
  };

  return (
    <div className="signup-page">
      <HeaderBar />
      <div className="signup-box">
        <h1>Client Sign Up</h1>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSignUp} className="signup-form">
          <input type="email" name="email" placeholder="Email" required onChange={handleChange} />
          
          <input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            required
            onChange={handleChange}
            style={{ borderColor: !passwordsMatch && formData.verifyPassword ? 'red' : '' }}
          />
          
          <input
            type={showPassword ? 'text' : 'password'}
            name="verifyPassword"
            placeholder="Verify Password"
            required
            onChange={handleChange}
            style={{ borderColor: !passwordsMatch && formData.verifyPassword ? 'red' : '' }}
          />

          {/* Show/Hide Password Button */}
          <button
            type="button"
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? 'Hide Password' : 'Show Password'}
          </button>

          <input type="text" name="name" placeholder="Full Name" required onChange={handleChange} />
          <input type="text" name="phone" placeholder="Phone Number" required onChange={handleChange} />
          <input type="text" name="companyName" placeholder="Company Name (optional)" onChange={handleChange} />

          <h3>Contact Preferences</h3>
          <label>
            <input
              type="checkbox"
              name="contactPreferences.email"
              onChange={(e) => handleChange({ target: { name: 'contactPreferences.email', value: e.target.checked } })}
            />
            Email
          </label>
          <label>
            <input
              type="checkbox"
              name="contactPreferences.phone"
              onChange={(e) => handleChange({ target: { name: 'contactPreferences.phone', value: e.target.checked } })}
            />
            Phone
          </label>

          <h3>Social Media Links</h3>
          <input type="text" name="socialMedia.linkedIn" placeholder="LinkedIn" onChange={handleChange} />
          <input type="text" name="socialMedia.twitter" placeholder="Twitter" onChange={handleChange} />
          <input type="text" name="socialMedia.facebook" placeholder="Facebook" onChange={handleChange} />
          <input type="text" name="socialMedia.instagram" placeholder="Instagram" onChange={handleChange} />
          <input type="text" name="socialMedia.youtube" placeholder="YouTube" onChange={handleChange} />

          <h3>Additional Information</h3>
          <textarea name="notes" placeholder="Additional Notes" onChange={handleChange} />

          <button type="submit" className="signup-button">Sign Up</button>
        </form>
      </div>
    </div>
  );
}

export default ClientSignUp;
