import React from 'react';
import './CareerOptions.css'; // Import your CSS
import HeaderBar from './HeaderBar'; // Import the HeaderBar for consistent navigation

function CareerOptions() {
  return (
    <div className="main-container">
      {/* Include the header bar */}
      <HeaderBar />

      <div className="bubble-container">
        <h1>Join Our Team at AigencyOne</h1>
        <p>
          We are always on the lookout for passionate and driven individuals to join our growing team. Whether you're a skilled developer or a motivated sales professional, we offer exciting opportunities to grow your career with us.
        </p>
      </div>

      {/* Piecework Developers Section */}
      <div className="bubble-container">
        <h2>Piecework Developers</h2>
        <p>
          Are you a talented developer looking to take on flexible projects? Join AigencyOne as a piecework developer. You’ll be paid per project, giving you the flexibility to work on your own terms while delivering top-notch web and mobile applications for our clients.
        </p>
        <ul className="career-job-list">
          <li>Work remotely on diverse and exciting projects.</li>
          <li>Earn per project – the more you work, the more you earn.</li>
          <li>Collaborate with our experienced team to deliver quality results.</li>
          <li>Flexibility to manage your schedule while working on high-demand applications.</li>
        </ul>
        <button className="btn primary-btn">Apply as Developer</button>
      </div>

      {/* Commission-Only Sales Agents Section */}
      <div className="bubble-container">
        <h2>Commission-Only Sales Agents</h2>
        <p>
          Do you thrive in a fast-paced sales environment? At AigencyOne, we're looking for motivated sales agents to join our team on a commission-only basis. The earning potential is limitless – the more deals you close, the more you earn!
        </p>
        <ul className="career-job-list">
          <li>Earn commission on every sale you bring in.</li>
          <li>Remote work with the ability to set your own hours.</li>
          <li>Sell innovative web and mobile application solutions to businesses.</li>
          <li>Opportunities for growth within our sales team.</li>
        </ul>
        <button className="btn primary-btn">Apply as Sales Agent</button>
      </div>

      {/* Contact Us Section */}
      <div className="bubble-container">
        <h2>Interested in joining us?</h2>
        <p>
          If you're interested in working with AigencyOne as a developer or sales agent, get in touch with us today! We're excited to hear from you.
        </p>
        <button className="btn contact-btn">Contact Us</button>
      </div>
    </div>
  );
}

export default CareerOptions;
