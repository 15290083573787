import React, { useEffect, useState } from "react";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // For getting the current user's ID
import "./ExecutiveSettings.css";
import ExecutiveSidebar from "./ExecutiveSidebar";

const ExecutiveSetting = () => {
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    phone: "",
    position: "Executive",
  });
  const [notifications, setNotifications] = useState({
    emailUpdates: false,
    smsUpdates: false,
    appNotifications: false,
  });
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser?.uid; // Ensure user is authenticated

  useEffect(() => {
    const fetchSettings = async () => {
      if (userId) {
        const userDoc = doc(db, "Users", userId);
        const docSnap = await getDoc(userDoc);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setProfileData({
            name: data.name || "",
            email: data.email || "",
            phone: data.phone || "",
            position: data.position || "Executive",
          });
          setNotifications({
            emailUpdates: data.notifications?.emailUpdates || false,
            smsUpdates: data.notifications?.smsUpdates || false,
            appNotifications: data.notifications?.appNotifications || false,
          });
        }
      }
    };

    fetchSettings();
  }, [db, userId]);

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleNotificationChange = (e) => {
    const { name, checked } = e.target;
    setNotifications((prevData) => ({ ...prevData, [name]: checked }));
  };

  const saveSettings = async () => {
    if (userId) {
      try {
        const userDoc = doc(db, "Users", userId);
        await setDoc(userDoc, {
          ...profileData,
          notifications,
        }, { merge: true });
        alert("Settings saved successfully!");
      } catch (error) {
        console.error("Error saving settings:", error);
        alert("Failed to save settings. Please try again later.");
      }
    } else {
      alert("User is not authenticated.");
    }
  };

  return (
    <div className="executive-settings">
      <ExecutiveSidebar /> {/* Adjust based on your layout */}
      <h1>Settings</h1>
      
      <div className="settings-section">
        <h2>Profile Information</h2>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={profileData.name}
            onChange={handleProfileChange}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={profileData.email}
            onChange={handleProfileChange}
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            value={profileData.phone}
            onChange={handleProfileChange}
          />
        </div>
        <div className="form-group">
          <label>Position</label>
          <input type="text" name="position" value={profileData.position} disabled />
        </div>
      </div>

      <div className="settings-section">
        <h2>Notification Preferences</h2>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              name="emailUpdates"
              checked={notifications.emailUpdates}
              onChange={handleNotificationChange}
            />
            Email Updates
          </label>
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              name="smsUpdates"
              checked={notifications.smsUpdates}
              onChange={handleNotificationChange}
            />
            SMS Updates
          </label>
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              name="appNotifications"
              checked={notifications.appNotifications}
              onChange={handleNotificationChange}
            />
            App Notifications
          </label>
        </div>
      </div>

      <button className="save-button" onClick={saveSettings}>
        Save Settings
      </button>
    </div>
  );
};

export default ExecutiveSetting;
