import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase/firebaseConfig';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import './ExecutiveTeamManagement.css';
import ExecutiveSidebar from './ExecutiveSidebar';

function ExecutiveTeamManagement() {
  const [employees, setEmployees] = useState([]);
  const [editEmployee, setEditEmployee] = useState(null);
  const [updatedData, setUpdatedData] = useState({
    name: '',
    email: '',
    phone: '',
    department: '',
    role: '',
    status: 'active',
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [departmentFilter, setDepartmentFilter] = useState('All');
  const [roleFilter, setRoleFilter] = useState('All');

  useEffect(() => {
    const fetchEmployees = async () => {
      const employeeSnapshot = await getDocs(collection(db, 'Employees'));
      const employeeList = employeeSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        dateHired: doc.data().dateHired ? doc.data().dateHired.toDate().toLocaleDateString() : 'N/A',
      }));
      setEmployees(employeeList);
      setLoading(false);
    };

    fetchEmployees();
  }, []);

  const handleEditClick = (employee) => {
    setEditEmployee(employee);
    setUpdatedData({
      name: employee.name,
      email: employee.email,
      phone: employee.phone,
      department: employee.department,
      role: employee.role,
      status: employee.status,
    });
  };

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdateSubmit = async () => {
    if (editEmployee) {
      const employeeRef = doc(db, 'Employees', editEmployee.id);
      await updateDoc(employeeRef, updatedData);
      setEmployees((prevEmployees) =>
        prevEmployees.map((emp) => (emp.id === editEmployee.id ? { ...emp, ...updatedData } : emp))
      );
      setEditEmployee(null);
      setUpdatedData({ name: '', email: '', phone: '', department: '', role: '', status: 'active' });
    }
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'Employees', id));
    setEmployees((prevEmployees) => prevEmployees.filter((emp) => emp.id !== id));
  };

  const filteredEmployees = employees.filter((employee) => {
    return (
      (departmentFilter === 'All' || employee.department === departmentFilter) &&
      (roleFilter === 'All' || employee.role === roleFilter) &&
      (employee.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
       employee.email.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  if (loading) return <div>Loading team data...</div>;

  return (
    <div className="main-content-wrapper">
      <ExecutiveSidebar />
      <div className="executive-team-management-container">
        <h2>Executive Team Management</h2>

        {/* Search and Filter Section */}
        <div className="filter-section">
          <input
            type="text"
            placeholder="Search by name or email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          <select
            value={departmentFilter}
            onChange={(e) => setDepartmentFilter(e.target.value)}
            className="filter-dropdown"
          >
            <option value="All">All Departments</option>
            {[...new Set(employees.map((emp) => emp.department))].map((dept) => (
              <option key={dept} value={dept}>{dept}</option>
            ))}
          </select>
          <select
            value={roleFilter}
            onChange={(e) => setRoleFilter(e.target.value)}
            className="filter-dropdown"
          >
            <option value="All">All Roles</option>
            {[...new Set(employees.map((emp) => emp.role))].map((role) => (
              <option key={role} value={role}>{role}</option>
            ))}
          </select>
        </div>

        <table className="team-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Department</th>
              <th>Role</th>
              <th>Status</th>
              <th>Date Hired</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map((employee) => (
              <tr key={employee.id}>
                <td>{employee.name}</td>
                <td>{employee.email}</td>
                <td>{employee.phone}</td>
                <td>{employee.department}</td>
                <td>{employee.role}</td>
                <td>{employee.status}</td>
                <td>{employee.dateHired}</td>
                <td>
                  <button onClick={() => handleEditClick(employee)} className="edit-button">Edit</button>
                  <button onClick={() => handleDelete(employee.id)} className="delete-button">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {editEmployee && (
          <div className="edit-form">
            <h3>Edit Employee</h3>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={updatedData.name}
              onChange={handleUpdateChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={updatedData.email}
              onChange={handleUpdateChange}
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={updatedData.phone}
              onChange={handleUpdateChange}
            />
            <input
              type="text"
              name="department"
              placeholder="Department"
              value={updatedData.department}
              onChange={handleUpdateChange}
            />
            <input
              type="text"
              name="role"
              placeholder="Role"
              value={updatedData.role}
              onChange={handleUpdateChange}
            />
            <select name="status" value={updatedData.status} onChange={handleUpdateChange}>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            <button onClick={handleUpdateSubmit} className="save-button">Save</button>
            <button onClick={() => setEditEmployee(null)} className="cancel-button">Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExecutiveTeamManagement;
