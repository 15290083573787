import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../firebase/firebaseConfig'; // Adjust the path if needed
import { useNavigate } from 'react-router-dom';
import './ClientForgotPassword.css';
import HeaderBar from '../../landingpage/HeaderBar';

function ClientForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Check your inbox.');
    } catch (err) {
      setError('Failed to send password reset email. Please check your email address.');
    }
  };

  const handleLoginRedirect = () => {
    navigate('/client-login');
  };

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-video">
        <video autoPlay loop muted playsInline className="background-video">
          <source src="https://storage.googleapis.com/logohostbucket/TechBackground1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <HeaderBar />

      <div className="forgot-password-box">
        <h1>Forgot Password</h1>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleResetPassword} className="forgot-password-form">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="forgot-password-input"
          />
          <button type="submit" className="forgot-password-button">Reset Password</button>
        </form>

        <div className="forgot-password-actions">
          <p>Remembered your password?</p>
          <button onClick={handleLoginRedirect} className="login-link">
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClientForgotPassword;
