import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ExecutiveSidebar.css';

function ExecutiveSidebar() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="executive-sidebar">
      <h2 className="sidebar-title">Executive Dashboard</h2>
      <ul className="sidebar-menu">
        <li onClick={() => handleNavigation('/executive-dashboard')}>
          Dashboard Overview
        </li>
        <li onClick={() => handleNavigation('/executive-team-management')}>
          Team Management
        </li>
        <li onClick={() => handleNavigation('/executive-projects')}>
          Project Overview
        </li>
        <li onClick={() => handleNavigation('/executive-financial-reports')}>
          Financial Reports
        </li>
        <li onClick={() => handleNavigation('/executive-client-accounts')}>
          Client Accounts
        </li>
        <li onClick={() => handleNavigation('/executive-analytics')}>
          Analytics
        </li>
        <li onClick={() => handleNavigation('/executive-settings')}>
          Settings
        </li>
      </ul>
      <div className="sidebar-footer">
        <button onClick={() => handleNavigation('/logout')}>Log Out</button>
      </div>
    </div>
  );
}

export default ExecutiveSidebar;
