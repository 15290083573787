import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import './DevelopmentPartners.css';
import HeaderBar from './HeaderBar';

const DevelopmentPartners = () => {
  const [developers, setDevelopers] = useState([]);
  const [filteredDevelopers, setFilteredDevelopers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('All');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeveloper, setSelectedDeveloper] = useState(null);

  useEffect(() => {
    const fetchDevelopers = async () => {
      const db = getFirestore();
      const developersCollection = collection(db, 'Developers');
      const developersSnapshot = await getDocs(developersCollection);
      const developersData = await Promise.all(
        developersSnapshot.docs.map(async (doc) => {
          const developer = doc.data();
          const videos = await fetchVideos(developer.videoFolder);
          return { ...developer, id: doc.id, videos };
        })
      );
      setDevelopers(developersData);
      setFilteredDevelopers(developersData);
    };

    const fetchVideos = async (folderPath) => {
      const storage = getStorage();
      const folderRef = ref(storage, folderPath);
      try {
        const videoRefs = await listAll(folderRef);
        const urlPromises = videoRefs.items.map((itemRef) => getDownloadURL(itemRef));
        return await Promise.all(urlPromises);
      } catch (error) {
        console.error("Error fetching video URLs:", error);
        return [];
      }
    };

    fetchDevelopers();
  }, []);

  useEffect(() => {
    const filtered = developers.filter(dev => 
      dev.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filterType === 'All' || dev.skills.includes(filterType))
    );
    setFilteredDevelopers(filtered);
  }, [searchTerm, filterType, developers]);

  const openModal = (developer) => {
    setSelectedDeveloper(developer);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDeveloper(null);
  };

  return (
    <div className="development-partners">
      <HeaderBar />
      <div className="content-container">
        <h1 className="title">Our Development Partners</h1>

        {/* Search and Filter */}
        <div className="search-filter">
          <input 
            type="text"
            placeholder="Search developers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
          />
          <select 
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            className="filter-dropdown"
          >
            <option value="All">All</option>
            <option value="Mobile Apps">Mobile Apps</option>
            <option value="Web Apps">Web Apps</option>
            <option value="Web and Mobile Apps">Web and Mobile Apps</option>
            <option value="Desktop Applications">Desktop Applications</option>
            <option value="VR Applications">VR Applications</option>
            <option value="AI Chatbot">AI Chatbot</option>
          </select>
        </div>

        {/* Developer Cards */}
        <div className="developer-cards">
          {filteredDevelopers.map(dev => (
            <div key={dev.id} className="developer-card">
              <div className="card-content">
                {/* Circular profile picture */}
                <img src={dev.photoUrl} alt={`${dev.name} profile`} className="developer-photo" />
                <h2 className="developer-name">{dev.name}</h2>
                <p className="developer-role"><strong>{dev.role}</strong></p>
                <p className="developer-rating">{dev.rating} | {dev.jobsCompleted} jobs</p>
                <p className="developer-description">{dev.description}</p>
                <button className="view-projects-button" onClick={() => openModal(dev)}>
                  View Projects
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Modal for Developer Videos */}
        {isModalOpen && selectedDeveloper && (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button className="close-modal" onClick={closeModal}>X</button>
              <h2>{selectedDeveloper.name}'s Projects</h2>
              <div className="video-gallery">
                {selectedDeveloper.videos.map((videoUrl, index) => (
                  <div key={index} className="video-container">
                    <video controls src={videoUrl} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DevelopmentPartners;
