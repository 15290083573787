import React, { useState } from 'react';
import { auth, db } from '../../../firebase/firebaseConfig';
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import './TeamSignUp.css';

function TeamSignUp() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    department: '',
    role: '',
    status: 'active',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSignUp = async () => {
    if (!formData.name || !formData.email || !formData.role) {
      setError('Please fill in all required fields');
      return;
    }

    const temporaryPassword = Math.random().toString(36).slice(-8); // Generate a temporary password

    try {
      // Create the user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, temporaryPassword);
      const user = userCredential.user;

      // Add the user to Firestore's Employees collection
      await setDoc(doc(db, 'Employees', user.uid), {
        ...formData,
        uid: user.uid,
        dateHired: serverTimestamp(),
        projects: [],
      });

      // Send password reset email to prompt the user to set their password
      await sendPasswordResetEmail(auth, formData.email);

      setSuccess(true);
      setError('');
      setFormData({
        name: '',
        email: '',
        phone: '',
        department: '',
        role: '',
        status: 'active',
      });
    } catch (error) {
      console.error('Error signing up new employee:', error);
      setError('Failed to sign up new employee');
    }
  };

  return (
    <div className="team-signup-container">
      <h2>Team Sign Up</h2>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">Sign-up successful! Check your email to set your password.</p>}
      <div className="signup-form">
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
        />
        <input
          type="text"
          name="department"
          placeholder="Department"
          value={formData.department}
          onChange={handleChange}
        />
        <input
          type="text"
          name="role"
          placeholder="Role"
          value={formData.role}
          onChange={handleChange}
          required
        />
        <button onClick={handleSignUp} className="signup-button">Sign Up</button>
      </div>
    </div>
  );
}

export default TeamSignUp;
