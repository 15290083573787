// SalesDashboard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../../firebase/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, doc, getDoc, query, where } from 'firebase/firestore';
import './SalesDashboard.css';
import SalesSidebar from './SalesSidebar';

function SalesDashboard() {
  const [employee, setEmployee] = useState(null);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [leads, setLeads] = useState([]);
  const [revenue, setRevenue] = useState(0);
  const target = 100000; // Example target
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          // Get employee document
          const employeeDoc = await getDoc(doc(db, 'Employees', currentUser.uid));
          if (employeeDoc.exists()) {
            const employeeData = employeeDoc.data();
            setEmployee(employeeData);

            if (employeeData.id) {
              // Fetch projects, tasks, leads, and revenue associated with this employee
              await fetchProjects(employeeData.id);
              await fetchTasks(employeeData.id);
              await fetchLeads();
              await fetchRevenue();
            }
          } else {
            console.error("Employee data not found");
            navigate('/team-login');
          }
        } catch (error) {
          console.error("Error fetching employee data:", error);
          navigate('/team-login');
        } finally {
          setLoading(false);
        }
      } else {
        navigate('/team-login'); // Redirect to login if not authenticated
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [navigate]);

  const fetchLeads = async () => {
    try {
      const leadsSnapshot = await getDocs(collection(db, 'Leads'));
      const leadsList = leadsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setLeads(leadsList);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  const fetchRevenue = async () => {
    try {
      const paymentsSnapshot = await getDocs(collection(db, 'Payments'));
      const totalRevenue = paymentsSnapshot.docs.reduce((acc, doc) => acc + (doc.data().amount || 0), 0);
      setRevenue(totalRevenue);
    } catch (error) {
      console.error("Error fetching revenue:", error);
    }
  };

  const fetchProjects = async (employeeId) => {
    if (!employeeId) return; // Check if employeeId exists
    try {
      const projectsQuery = query(
        collection(db, 'Projects'),
        where('assignedEmployees', 'array-contains', employeeId)
      );
      const projectSnapshot = await getDocs(projectsQuery);
      const projectList = projectSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setProjects(projectList);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchTasks = async (employeeId) => {
    if (!employeeId) return; // Check if employeeId exists
    try {
      const tasksQuery = query(
        collection(db, 'Tasks'),
        where('assignedEmployees', 'array-contains', employeeId)
      );
      const tasksSnapshot = await getDocs(tasksQuery);
      const tasksList = tasksSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTasks(tasksList);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const progressPercentage = (revenue / target) * 100;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!employee) {
    return null;
  }

  return (
    <div className="sales-dashboard-container">
      <div className="dashboard-content">
        <SalesSidebar />
        <h1>Welcome, {employee.name}!</h1>
        <p>Sales Dashboard - Track your leads, revenue, and tasks.</p>

        {/* Overview Section */}
        <section className="dashboard-overview">
          <div className="overview-card">
            <h3>Total Revenue</h3>
            <p>${revenue.toLocaleString()}</p>
          </div>
          <div className="overview-card">
            <h3>Revenue Target</h3>
            <p>${target.toLocaleString()}</p>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progressPercentage}%` }}></div>
            </div>
          </div>
          <div className="overview-card">
            <h3>Leads</h3>
            <p>{leads.length} Active Leads</p>
          </div>
          <div className="overview-card">
            <h3>Pending Tasks</h3>
            <p>{tasks.length} Tasks</p>
          </div>
        </section>

        {/* Projects Section */}
        <section className="projects-section">
          <h2>Your Projects</h2>
          <ul className="project-list">
            {projects.map((project) => (
              <li key={project.id}>
                <h3>{project.name}</h3>
                <p>Description: {project.description}</p>
                <p>Status: {project.status}</p>
              </li>
            ))}
          </ul>
        </section>

        {/* Tasks Section */}
        <section className="tasks-section">
          <h2>Your Pending Tasks</h2>
          <ul className="task-list">
            {tasks.map((task) => (
              <li key={task.id}>
                <h3>{task.title}</h3>
                <p>Due Date: {task.dueDate}</p>
                <p>Status: {task.status}</p>
              </li>
            ))}
          </ul>
        </section>

        {/* Performance Metrics */}
        <section className="performance-section">
          <h2>Performance Metrics</h2>
          <div className="performance-metrics">
            <div className="metric-card">
              <h3>Conversion Rate</h3>
              <p>45%</p> {/* Placeholder percentage */}
            </div>
            <div className="metric-card">
              <h3>Average Deal Size</h3>
              <p>${leads.length ? (revenue / leads.length).toFixed(2) : 'N/A'}</p> {/* Avoid division by zero */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SalesDashboard;
