import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase/firebaseConfig';
import './ClientLogin.css';
import HeaderBar from '../../landingpage/HeaderBar';

function ClientLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/client-dashboard'); // Redirect to client dashboard upon successful login
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  const handleSignUp = () => {
    navigate('/sign-up');
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <div className="login-page">
      <div className="login-video">
        <video autoPlay loop muted playsInline className="background-video">
          <source src="https://storage.googleapis.com/logohostbucket/TechBackground1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <HeaderBar />

      <div className="login-box">
        <h1>Client Login</h1>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin} className="login-form">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="login-input"
          />
          <button type="submit" className="login-button">Login</button>
        </form>

        <div className="login-actions">
          <button onClick={handleForgotPassword} className="forgot-password-link">
            Forgot Password?
          </button>
          <button onClick={handleSignUp} className="sign-up-link">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClientLogin;
