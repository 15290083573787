import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import "./ExecutiveAnalytics.css";
import { LineChart, BarChart } from "recharts"; // Recharts is a commonly used charting library

const ExecutiveAnalytics = () => {
  const [totalClients, setTotalClients] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [monthlyRevenue, setMonthlyRevenue] = useState([]);
  const [projectsPerMonth, setProjectsPerMonth] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    // Fetch client data
    const fetchClients = async () => {
      const clientsSnapshot = await getDocs(collection(db, "Clients"));
      setTotalClients(clientsSnapshot.size);
    };

    // Fetch project data
    const fetchProjects = async () => {
      const projectsSnapshot = await getDocs(collection(db, "Projects"));
      setTotalProjects(projectsSnapshot.size);

      // Analyzing projects per month 
      const projectsData = projectsSnapshot.docs.map(doc => doc.data());
      const monthlyProjects = {}; // store project count by month
      projectsData.forEach(project => {
        const month = new Date(project.startDate).getMonth(); // assuming projects have a startDate
        monthlyProjects[month] = (monthlyProjects[month] || 0) + 1;
      });
      setProjectsPerMonth(Object.entries(monthlyProjects).map(([month, count]) => ({ month, count })));
    };

    // Fetch payment/revenue data
    const fetchRevenue = async () => {
      const paymentsSnapshot = await getDocs(collection(db, "Payments"));
      let total = 0;
      const monthlyRevenueData = {}; // store revenue by month

      paymentsSnapshot.docs.forEach(doc => {
        const payment = doc.data();
        total += payment.amount; // assuming each payment has an 'amount' field
        const month = new Date(payment.date).getMonth(); // assuming payments have a date field
        monthlyRevenueData[month] = (monthlyRevenueData[month] || 0) + payment.amount;
      });

      setTotalRevenue(total);
      setMonthlyRevenue(Object.entries(monthlyRevenueData).map(([month, amount]) => ({ month, amount })));
    };

    fetchClients();
    fetchProjects();
    fetchRevenue();
  }, [db]);

  return (
    <div className="executive-analytics">
      <h1>Executive Dashboard</h1>

      {/* Overview Metrics */}
      <div className="overview-metrics">
        <div className="metric">
          <h3>Total Clients</h3>
          <p>{totalClients}</p>
        </div>
        <div className="metric">
          <h3>Total Projects</h3>
          <p>{totalProjects}</p>
        </div>
        <div className="metric">
          <h3>Total Revenue</h3>
          <p>${totalRevenue.toLocaleString()}</p>
        </div>
      </div>

      {/* Trends */}
      <div className="trends">
        <h2>Monthly Revenue Trend</h2>
        <LineChart width={600} height={300} data={monthlyRevenue}>
          {/* Additional setup for LineChart */}
        </LineChart>

        <h2>Projects Completed Per Month</h2>
        <BarChart width={600} height={300} data={projectsPerMonth}>
          {/* Additional setup for BarChart */}
        </BarChart>
      </div>
    </div>
  );
};

export default ExecutiveAnalytics;
