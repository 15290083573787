import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styles from './StripePaymentModal.module.css';

// Load your Stripe publishable key here
const stripePromise = loadStripe('pk_live_51Q5GQdC7NNMfjuxCAuATAh8QDRoZNwF6FlbbKdnUwlsrkx89589anDn8cvN847ct5bArxl8myYAtHl1DrhVWssIQ00JkgaSDYq');

function CheckoutForm({ projects, amount, onClose }) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  const [paymentAmount, setPaymentAmount] = useState(amount);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    try {
      // Make a request to your backend to create a PaymentIntent
      const response = await fetch('/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: paymentAmount }),
      });

      const { clientSecret } = await response.json();

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: 'Client Name',
          },
        },
      });

      if (paymentResult.error) {
        setError(paymentResult.error.message);
      } else if (paymentResult.paymentIntent.status === 'succeeded') {
        alert('Payment successful!');
        onClose();
      }
    } catch (err) {
      setError('Payment failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <h2>Enter Payment Details</h2>

      {/* Project Selection */}
      <label>Project:</label>
      <select
        value={selectedProject}
        onChange={(e) => setSelectedProject(e.target.value)}
        className={styles.select}
        required
      >
        <option value="">Select a project</option>
        {projects.map((project) => (
          <option key={project.id} value={project.id}>
            {project.name}
          </option>
        ))}
      </select>

      {/* Payment Amount Input */}
      <label>Payment Amount:</label>
      <input
        type="number"
        value={paymentAmount}
        onChange={(e) => setPaymentAmount(e.target.value)}
        className={styles.input}
        min="1"
        required
      />

      <CardElement className={styles.cardElement} />
      {error && <p className={styles.error}>{error}</p>}

      <button type="submit" className={styles.payButton} disabled={!stripe || loading}>
        {loading ? 'Processing...' : 'Pay Now'}
      </button>
      <button type="button" onClick={onClose} className={styles.cancelButton}>
        Cancel
      </button>
    </form>
  );
}

export default function StripePaymentModal({ projects, amount, onClose }) {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <Elements stripe={stripePromise}>
          <CheckoutForm projects={projects} amount={amount} onClose={onClose} />
        </Elements>
      </div>
    </div>
  );
}
