import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase/firebaseConfig';
import { doc, collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import styles from './ClientDashboard.module.css';
import ClientSidebar from './ClientSidebar';
import StripePaymentModal from '../payments/StripePaymentModal';

function ClientDashboard() {
  const [clientData, setClientData] = useState({});
  const [projects, setProjects] = useState([]);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state to wait for auth check
  const [showPaymentModal, setShowPaymentModal] = useState(false); // Modal visibility state
  const [selectedProject, setSelectedProject] = useState(null); // Project for which payment is being made
  const [paymentAmount, setPaymentAmount] = useState(0); // Payment amount state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        const clientDocRef = doc(db, 'Clients', user.uid);
        
        try {
          const clientSnapshot = await getDoc(clientDocRef);
          if (clientSnapshot.exists()) {
            setClientData(clientSnapshot.data());
            await fetchProjects(user.uid);
            await fetchPayments(user.uid);
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error("Error fetching client data:", error);
        } finally {
          setLoading(false); // Set loading to false once data is fetched
        }
      } else {
        // If user is not authenticated, check again to allow Firebase auth to initialize
        auth.onAuthStateChanged((user) => {
          if (user) {
            fetchData(); // Fetch data again if the user becomes available
          } else {
            navigate('/login');
          }
          setLoading(false);
        });
      }
    };

    fetchData();
  }, [navigate]);

  const fetchProjects = async (clientId) => {
    const projectsQuery = query(
      collection(db, 'Projects'),
      where('clientId', '==', clientId)
    );

    const projectSnapshots = await getDocs(projectsQuery);
    const projectList = projectSnapshots.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setProjects(projectList);
  };

  const fetchPayments = async (clientId) => {
    const paymentsQuery = query(
      collection(db, 'Payments'),
      where('clientId', '==', clientId)
    );

    const paymentSnapshots = await getDocs(paymentsQuery);
    const paymentList = paymentSnapshots.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setPayments(paymentList);
  };

  const calculateProgress = (project) => {
    const completedMilestones = project.milestones?.filter(milestone => milestone.status === 'Completed').length || 0;
    const totalMilestones = project.milestones?.length || 1;
    return (completedMilestones / totalMilestones) * 100;
  };

  // Handler to open the payment modal with the selected project
  const handleMakePayment = (project) => {
    setSelectedProject(project);
    setPaymentAmount(0); // Reset the payment amount
    setShowPaymentModal(true);
  };

  if (loading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return (
    <div className={styles.clientDashboard}>
      <ClientSidebar />
      <div className={styles.clientDashboardContent}>
        <h1>Welcome to Your Dashboard</h1>

        <section className={styles.dashboardOverview}>
          <h2>Your Overview</h2>
          <div className={styles.overviewCards}>
            <div className={styles.overviewCard}>
              <h3>Projects</h3>
              <p>{projects.length} Active Projects</p>
            </div>
            <div className={styles.overviewCard}>
              <h3>Invoices</h3>
              <p>{payments.filter(payment => !payment.isPaid).length} Unpaid Invoices</p>
            </div>
            <div className={styles.overviewCard}>
              <h3>Outstanding Balance</h3>
              <p>${clientData.billingDetails?.outstandingBalance || 0}</p>
            </div>
          </div>
        </section>

        <section className={styles.projectsSection}>
          <h2>Your Projects</h2>
          <div className={styles.projectList}>
            {projects.map((project) => (
              <div key={project.id} className={styles.projectItem}>
                <h3>{project.name}</h3>
                <p>Status: {project.status}</p>
                <div className={styles.progressBar}>
                  <div className={styles.progress} style={{ width: `${calculateProgress(project)}%` }}></div>
                </div>
                <p>Progress: {calculateProgress(project).toFixed(2)}%</p>
                <button
                  className={styles.makePaymentButton}
                  onClick={() => handleMakePayment(project)}
                >
                  Make Payment
                </button>
              </div>
            ))}
          </div>
        </section>
      </div>

      {/* Stripe Payment Modal */}
      {showPaymentModal && selectedProject && (
        <StripePaymentModal
          projects={[selectedProject]} // Pass only the selected project
          amount={paymentAmount}
          onClose={() => setShowPaymentModal(false)} // Close the modal
        />
      )}
    </div>
  );
}

export default ClientDashboard;
